import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Grid, MenuItem, Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  updateGroupPolicy,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { buttonIconStyles } from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { getAllPolicies } from '../../../../../../Redux/Slices/Policy/policyHandler';
import { redirectToEditScreen } from '../../../../../../Redux/Slices/Application/appSlice';

function ShopifyPlusGroupSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier, description, policy, defaultPolicyID,
  } = useSelector(
    (state: RootState) => state.groupSettings,
  );
  const { allPolicies } = useSelector(
    (state: RootState) => state.policySettings,
  );

  useEffect(() => {
    dispatch(getAllPolicies());
  }, []);

  return (
    <Box>
      <Grid container pb={2} rowGap={2}>
        <Grid item xs={5}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        <Grid item xs={5}>
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
        <Grid item xs={5}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={7}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box display="flex" width="100%" mt={1} gap="1rem">
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          onClick={() => dispatch(redirectToEditScreen(true))}
        >
          Skip
        </Button>
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
        >
          <DeleteOutlineOutlinedIcon
            sx={{ ...buttonIconStyles, color: '#373737' }}
          />
          &nbsp;&nbsp; Cancel
        </Button>
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(configureNewGroup())}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </Box>
  );
}

export default ShopifyPlusGroupSettings;
