import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../../../utils/utility';
import MainLayout from '../../../components/MainLayout';
import ProxyDetails from './ProxyDetails';
import EnableFeatures from './EnableFeatures';
import { SubTitle } from '../../../styles/subtitle';
import { updateProxyState } from '../../../Redux/Slices/Policy/Proxy/proxy';
import { ResponsiveTypography } from '../../../styles/inputLabel';

function AddProxy() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [type, setType] = useState('save');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const identifier = urlParams.has('identifier')
    ? urlParams.get('identifier')
    : '';
  const path = urlParams.has('path') ? urlParams.get('path') : '';
  useEffect(() => {
    if (identifier && path) {
      setType('update');
    }
  }, []);

  useEffect(() => {
    async function editProxyDetails() {
      if (identifier !== '' && path !== '') {
        const token = getCookie('token');
        axios
          .post(
            `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-proxy-details`,
            {
              identifier,
              path,
            },
            {
              headers: {
                Authorization: token,
              },
            },
          )
          .then((res) => {
            var domainPathList = [];
            if (
              res?.data?.proxy?.cookie_domain_rewrite_feature
                ?.cookie_path_list
              && res?.data?.proxy?.cookie_domain_rewrite_feature
                ?.cookie_domain_list
            ) {
              for (
                let i = 0;
                i
                < res?.data?.proxy?.cookie_domain_rewrite_feature
                  ?.cookie_domain_list.length;
                i++
              ) {
                domainPathList.push({
                  domain:
                    res?.data?.proxy?.cookie_domain_rewrite_feature
                      ?.cookie_domain_list[i],
                  path:
                    res?.data?.proxy?.cookie_domain_rewrite_feature
                      ?.cookie_path_list[i],
                });
              }
            } else {
              domainPathList = [{ domain: '', path: '' }];
            }
            const proxyDetails = {
              proxyName: res?.data?.proxy?.identifier,
              proxyLink: res?.data?.proxy?.path,
              primaryServerURL: res?.data?.proxy?.server_url,
              enableByIP: res?.data?.proxy?.proxying_by_ip,
              id: res?.data?.proxy?._id,
              IPRestriction: {
                enable: res?.data?.proxy?.ip_restriction_feature?.enable,
                IPList:
                  res?.data?.proxy?.ip_restriction_feature?.ip_address_list,
                restrictIPs:
                  res?.data?.proxy?.ip_restriction_feature?.restrict_ips,
              },
              LocationBasedRestriction: {
                enable:
                  res?.data?.proxy?.location_based_restriction_feature?.enable,
                LocationList:
                  res?.data?.proxy?.location_based_restriction_feature
                    ?.location_based_restriction_list,
                restrictLocations:
                  res?.data?.proxy?.location_based_restriction_feature
                    ?.restrict_location,
              },
              CORS: {
                enable: res?.data?.proxy?.cors_feature?.enable,
                DomainList: res?.data?.proxy?.cors_feature?.domain_name_list,
              },
              CachingFeature: {
                enable: res?.data?.proxy?.caching_feature?.enable,
                cachingTime: res?.data?.proxy?.caching_feature?.caching_time,
              },
              LoadBalanceFeature: {
                enable: res?.data?.proxy?.load_balancing_feature?.enable,
                memberList:
                  res?.data?.proxy?.load_balancing_feature
                    ?.balancer_member_list,
              },
              RateLimitingFeature: {
                enable: res?.data?.proxy?.rate_limiting_feature?.enable,
                maxRequest:
                  res?.data?.proxy?.rate_limiting_feature?.max_number_requests,
                timeLimit: res?.data?.proxy?.rate_limiting_feature?.time_limit,
                whiteListPath:
                  res?.data?.proxy?.rate_limiting_feature
                    ?.whitelisted_path_list,
              },
              CookieDomainRewriteFeature: {
                enable: res?.data?.proxy?.cookie_domain_rewrite_feature?.enable,
                CookieDomainPathList: domainPathList,
              },
              Authentication: {
                enable: res?.data?.proxy?.authentication_feature?.enable,
                authenticationID:
                  res?.data?.proxy?.authentication_feature?.authentication_id,
                redirectAfterAuthentication:
                  res?.data?.proxy?.authentication_feature
                    ?.redirect_after_authentication,
              },
              Substitution: {
                enable: res?.data?.proxy?.substitution_feature?.enable,
                htmlString:
                  res?.data?.proxy?.substitution_feature
                    ?.substitution_member_list_string,
                locationHeader:
                  res?.data?.proxy?.substitution_feature
                    ?.substitution_member_list_location,
              },
            };
            dispatch(updateProxyState(proxyDetails));
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_ADD_PROXY_ERROR);
          });
      }
    }
    editProxyDetails();
  }, []);

  return (
    <MainLayout>
      <Box p={4}>
        <ToastContainer />
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <ResponsiveTypography variant="h6">
            Proxy Settings
          </ResponsiveTypography>
          <SubTitle>Configure your proxy settings here.</SubTitle>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          border="1px solid #E3E3E3"
          borderRadius="15px"
          p={4}
          mt={3}
          gap={1}
        >
          <Box
            display="flex"
            px={1}
            borderBottom="1px solid #E3E3E3"
            width="100%"
          >
            <Button
              sx={{
                width: '9rem',
                color: 'black',
                borderBottom: step === 1 ? '3px solid #000000' : '',
                borderRadius: '0px !important',
              }}
              onClick={() => {
                setStep(1);
              }}
            >
              Proxy Details
            </Button>
            <Button
              sx={{
                width: '9rem',
                color: 'black',
                borderBottom: step === 2 ? '3px solid #000000' : '',
                borderRadius: '0px !important',
              }}
              onClick={() => {
                setStep(2);
              }}
            >
              Enable Features
            </Button>
          </Box>
          <Box width="100%">
            {step === 1 && <ProxyDetails setStep={setStep} />}
            {step === 2 && (
              <EnableFeatures step={step} setStep={setStep} type={type} />
            )}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AddProxy;
