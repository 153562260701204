import React, { useEffect } from 'react';
import {
  Box, Drawer, Grid, MenuItem, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { ResponsiveHeading } from '../../../../../../styles/heading';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledTextField } from '../../../../../../styles/textField';
import { StyledSelect, menuItemCSS } from '../../../../../../styles/select';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../../../styles/button';
import {
  updateDescription,
  updateGroupPolicy,
  updateIdentifier,
  updateClose,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { getAllPolicies } from '../../../../../../Redux/Slices/Policy/policyHandler';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';

function ShopifyAddGroupDrawer() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier, description, policy, close,
  } = useSelector(
    (state: RootState) => state.groupSettings,
  );

  const { allPolicies } = useSelector(
    (state: RootState) => state.policySettings,
  );
  useEffect(() => {
    dispatch(getAllPolicies());
  }, []);

  const updateGroup = () => {
    dispatch(configureNewGroup());
  };

  return (
    <Drawer
      open={close}
      onClose={() => dispatch(updateClose(false))}
      anchor="right"
    >
      <Box display="flex" flexDirection="column" width="50vh" py={5} px={2}>
        <ResponsiveHeading>Configure Groups</ResponsiveHeading>
        <Box pt={3}>
          <Grid container sx={{ rowGap: { xl: 1, lg: 1 } }}>
            <Grid item xs={12}>
              <ResponsiveTypography>Group Name</ResponsiveTypography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name="identifier"
                value={identifier}
                onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
              />
            </Grid>
            <Grid item xs={12}>
              <ResponsiveTypography>Group Description</ResponsiveTypography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name="description"
                value={description}
                onChange={(event: any) => dispatch(updateDescription(event.target.value))}
              />
            </Grid>
            <Grid item xs={12}>
              <ResponsiveTypography>Policy</ResponsiveTypography>
            </Grid>
            <Grid item xs={12} pb={2}>
              <StyledSelect
                value={policy}
                onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
              >
                {allPolicies.map((source: any) => (
                  <MenuItem
                    key={source.identifier}
                    value={source._id}
                    sx={menuItemCSS}
                  >
                    {source.identifier}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" gap="1rem" pt={2}>
          <Button
            variant="text"
            sx={lightBackgroundButtonStyles}
            onClick={() => dispatch(updateClose(false))}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={updateGroup}
          >
            Save & Next
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default ShopifyAddGroupDrawer;
