import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  customerID: string;
  email: string;
  currentActiveTenantID: string;
}

const initialState: InitialState = {
  customerID: '',
  email: '',
  currentActiveTenantID: '',
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    updateCustomer: (state, action: PayloadAction<any>) => {
      state.customerID = action.payload?.customer_id;
      state.email = action.payload?.email;
      state.currentActiveTenantID = action.payload?.current_active_tenant_id;
    },
  },
});

export const { updateCustomer } = customerSlice.actions;
export default customerSlice;
