import React from 'react';
import {
  Box, Drawer, Grid, TextField, Radio, Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateAuthenticationName,
  updateIssuer,
  updateLoginURL,
  updateLogoutURL,
  updateX509Certificate,
  updateBindingType,
  updateOpenDrawer,
} from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import { configureAuthenticationSource } from '../../Redux/Slices/Application/AuthenticationSource/authenticationSourceHandler';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { StyledTextField } from '../../styles/textField';
import { ResponsiveHeading } from '../../styles/heading';
import { SubLabel } from '../../styles/sublabel';
import AuthenticationSourceInformation from '../Application/Configuration/AuthenticationSource/info&links';
import {
  cyanBlueBackgroundButtonStyles,
} from '../../styles/button';
import { buttonIconStyles } from '../../styles/icon';
import Cancel from '../../components/cancelButton';

function AuthenticationSourceConfiguration() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    issuer,
    loginURL,
    logoutURL,
    X509Certificate,
    bindingType,
    drawer,
  } = useSelector((state: RootState) => state.authenticationSource);
  const updateApplicationAuthentication = () => {
    dispatch(configureAuthenticationSource());
  };
  return (
    <Drawer
      open={drawer}
      onClose={() => dispatch(updateOpenDrawer(false))}
      anchor="right"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="70vw"
        px={5}
        py={3}
        gap="1.5rem"
      >
        <Box>
          <ResponsiveHeading>Configure Authentication Source</ResponsiveHeading>
          <SubLabel>Please add details for authentication source</SubLabel>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box pr={3} py={1}>
            <Grid container sx={{ rowGap: { xl: 3, lg: 2 } }}>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  Application Name
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="identifier"
                  value={identifier || ''}
                  onChange={(e: any) => dispatch(updateAuthenticationName(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  IdP Entity ID or Issuer
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="issuer"
                  value={issuer || ''}
                  onChange={(e: any) => dispatch(updateIssuer(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  SAML Login URL
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="loginURL"
                  value={loginURL || ''}
                  onChange={(e: any) => dispatch(updateLoginURL(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  SAML Logout URL
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <StyledTextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  name="logoutURL"
                  value={logoutURL || ''}
                  onChange={(e: any) => dispatch(updateLogoutURL(e.target.value))}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  Select Binding Type for SSO Request
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="bindingType"
                  value={bindingType || ''}
                  onChange={(event: any) => dispatch(updateBindingType(event.target.value))}
                  sx={{ py: { xl: 0, lg: 0 } }}
                >
                  <FormControlLabel
                    value="HttpRedirect"
                    control={<Radio size="small" />}
                    label="HTTP-Redirect Binding"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: {
                          xl: '13px !important',
                          lg: '11px !important',
                          md: '8px !important',
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value="HttpPost"
                    control={<Radio size="small" />}
                    label="HTTP-Post Binding"
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: {
                          xl: '13px !important',
                          lg: '11px !important',
                          md: '8px !important',
                        },
                      },
                    }}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography variant="subtitle2">
                  X.509 Certificate
                </ResponsiveTypography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  fullWidth
                  multiline
                  name="X509Certificate"
                  rows={8}
                  maxRows={8}
                  value={X509Certificate || ''}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: {
                        xl: '15px !important',
                        lg: '10px !important',
                        md: '10px',
                      },
                      height: { xl: '13rem !important', lg: '7rem !important' },
                      padding: { lg: '4px 7px !important' },
                    },
                  }}
                  onChange={(e: any) => dispatch(updateX509Certificate(e.target.value))}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="40%">
            <AuthenticationSourceInformation />
          </Box>
        </Box>
        <Box display="flex" width="100%" mt={2} gap="1rem">
          <Cancel />
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={updateApplicationAuthentication}
          >
            Save and Next&nbsp;&nbsp;
            <ArrowForwardOutlinedIcon
              sx={{ ...buttonIconStyles, color: 'white' }}
            />
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default AuthenticationSourceConfiguration;
