import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArticleIcon from '@mui/icons-material/Article';
import {
  lightBackgroundButtonStyles,
  lightBlueBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { testSAMLConnection } from '../../../../../../utils/utility';
import { RootState } from '../../../../../../Redux/store';
import { buttonIconStyles } from '../../../../../../styles/icon';
import { apps } from '../../../../app';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';

function ShopifyBasicSettingsInformation() {
  const { app, authenticationID } = useSelector(
    (state: RootState) => state.application,
  );
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="1px solid #E3E3E3"
        py={2}
      >
        <ResponsiveTypography>Authentication Source</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            sx={lightBlueBackgroundButtonStyles}
            startIcon={(
              <SettingsEthernetOutlinedIcon
                sx={{ ...buttonIconStyles, color: '#0065FF' }}
              />
            )}
            onClick={() => testSAMLConnection(authenticationID, app)}
          >
            Test Connection
          </Button>
        </Box>
      </Box>
      {apps[app].appConfiguration}
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        borderBottom="1px solid #E3E3E3"
        py={2}
      >
        <ResponsiveTypography>Guide</ResponsiveTypography>
        <Box display="flex" flexDirection="row" gap="1rem">
          <Button
            startIcon={
              <YouTubeIcon sx={{ ...buttonIconStyles, color: 'red' }} />
            }
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
              },
            }}
          >
            Video Guide
          </Button>
          <Button
            sx={{
              ...lightBackgroundButtonStyles,
              width: {
                xxl: '12rem !important',
                xl: '10rem !important',
                lg: '8rem !important',
              },
            }}
            startIcon={
              <ArticleIcon sx={{ ...buttonIconStyles, color: 'blue' }} />
            }
            onClick={() => window.open(`${apps[app].guide}#step3`, '_blank')}
          >
            Setup Guide
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ShopifyBasicSettingsInformation;
