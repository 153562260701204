import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer, Box, Button, MenuItem, FormControl,
} from '@mui/material';
import toast from 'react-hot-toast';
import axios from 'axios';
import { RootState, AppDispatch } from '../../../Redux/store';
import { getCookie } from '../../../utils/utility';
import { ResponsiveTypography } from '../../../styles/inputLabel';
import {
  updateIssuer,
  updateLoginURL,
  updateLogoutURL,
  updateX509Certificate,
  updateAuthenticationName,
} from '../../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../styles/button';
import { menuItemCSS, StyledSelect } from '../../../styles/select';
import { StyledTextField } from '../../../styles/textField';
import { SmallHeading } from '../../../styles/smallHeading';

interface ImportIDPMetadataProps {
  open: boolean;
  setOpen: Function;
}

interface FormatSelectChangeEvent {
  target: {
    value: string;
  };
}

function ImportIDPMetadata({ open, setOpen }: ImportIDPMetadataProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { identifier } = useSelector(
    (state: RootState) => state.authenticationSource,
  );
  const [format, setFormat] = useState('File');
  const [url, setURL] = useState('');
  const [xml, setXml] = useState('');
  const closeDialog = () => {
    setOpen(!open);
  };
  const importMetadata = async () => {
    const token = await getCookie('token');
    if (format === 'URL' && !url) {
      toast.error('Empty URL Field');
      return;
    }
    if (format === 'Text' && !xml) {
      toast.error('Empty Text Field.');
      return;
    }
    if (format === 'File' && !xml) {
      toast.error('Please select file.');
      return;
    }
    axios
      .post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/idp-xml-data`, {
        url,
        format,
        token,
        xml,
      })
      .then((response) => {
        const idpMetadata = response?.data?.idp_metadata;
        setXml('');
        setURL('');
        dispatch(updateIssuer(idpMetadata?.entityID));
        dispatch(updateLoginURL(idpMetadata?.loginURL));
        dispatch(updateLogoutURL(idpMetadata?.logoutURL));
        dispatch(updateX509Certificate(idpMetadata?.certificate));
        toast.success('Metadata Uploaded');
        setOpen(!open);
      })
      .catch(() => {
        toast.error('Error fetching IDP metadata');
      });
  };
  const handleFormatChange = (event: FormatSelectChangeEvent) => {
    setFormat(event.target.value);
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e?.target?.result as string;
      if (content) {
        setXml(content);
      }
    };

    reader.readAsText(file);
  };

  return (
    <Drawer open={open} onClose={closeDialog} anchor="right">
      <SmallHeading p={2}>Import IDP Metadata</SmallHeading>
      <Box display="flex" flexDirection="column" gap="1rem" p={2}>
        <Box>
          <ResponsiveTypography>App Name</ResponsiveTypography>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            name="identifier"
            value={identifier || ''}
            onChange={(e: any) => dispatch(updateAuthenticationName(e.target.value))}
            disabled={window.location.href.includes('/edit')}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <ResponsiveTypography>Import Format</ResponsiveTypography>
          <FormControl fullWidth margin="dense" size="small">
            <StyledSelect
              size="small"
              sx={{ mb: 1.5 }}
              value={format}
              onChange={handleFormatChange}
            >
              <MenuItem value="URL" sx={menuItemCSS}>
                URL
              </MenuItem>
              <MenuItem value="Text" sx={menuItemCSS}>
                Text
              </MenuItem>
              <MenuItem value="File" sx={menuItemCSS}>
                File
              </MenuItem>
            </StyledSelect>
          </FormControl>
        </Box>
        {format === 'URL' ? (
          <StyledTextField
            fullWidth
            size="small"
            value={url}
            onChange={(event: any) => setURL(event.target.value)}
          />
        ) : format === 'Text' ? (
          <StyledTextField
            fullWidth
            multiline
            rows={10}
            maxRows={10}
            value={xml}
            onChange={(event: any) => setXml(event.target.value)}
          />
        ) : format === 'File' ? (
          <StyledTextField
            fullWidth
            type="file"
            size="small"
            InputProps={{ inputProps: { accept: '.xml' } }}
            onChange={handleFileUpload}
          />
        ) : (
          ''
        )}
      </Box>
      <Box display="flex" gap="1rem" p={2}>
        <Button
          sx={{ ...lightBackgroundButtonStyles, width: '5rem' }}
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <Button
          sx={{ ...cyanBlueBackgroundButtonStyles, width: '7rem' }}
          onClick={importMetadata}
        >
          Import
        </Button>
      </Box>
    </Drawer>
  );
}

export default ImportIDPMetadata;
