import { Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '1.3rem !important',
    '& .MuiSelect-select': {
      fontSize: '7px',
      padding: '3px 5px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '1.75rem !important',
    '& .MuiSelect-select': {
      fontSize: '10px',
      padding: '4px 7px',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2.0rem !important',
    '& .MuiSelect-select': {
      fontSize: '15px',
      padding: '8.5px 14px',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.1rem !important',
    '& .MuiSelect-select': {
      fontSize: '16px !important',
      padding: '8.5px 14px',
    },
  },
}));

export const menuItemCSS = {
  padding: {
    xxl: '1.1 !important',
    xl: '1 !important',
    lg: '0.5 !important',
    md: '0.3 !important',
  },
  fontSize: {
    xxl: '17px !important',
    xl: '15px !important',
    lg: '10px !important',
    md: '8px !important',
  },
};
