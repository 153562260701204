import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Grid, MenuItem, Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  getGroupApps,
  getConfiguredPolicies,
  updateGroupPolicy,
  updateRestrictedAppList,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { buttonIconStyles } from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { redirectToEditScreen } from '../../../../../../Redux/Slices/Application/appSlice';
import Cancel from '../../../../../../components/cancelButton';
import ManageRestriction from '../../../../Edit/AppConfiguration/GroupSettings/Common/manageRestriction';

function GroupSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    description,
    apps,
    policy,
    allPolicies,
    defaultPolicyID,
  } = useSelector((state: RootState) => state.groupSettings);
  const [, setFilteredData] = useState(apps);

  useEffect(() => {
    setFilteredData(apps);
    if (!policy) {
      dispatch(updateGroupPolicy(defaultPolicyID));
    }
  }, [apps]);
  useEffect(() => {
    dispatch(getGroupApps());
    dispatch(getConfiguredPolicies());
  }, []);
  useEffect(() => {
    dispatch(updateRestrictedAppList());
  }, [apps]);
  useEffect(() => {
    if (!policy) {
      dispatch(updateGroupPolicy(defaultPolicyID));
    }
  }, [defaultPolicyID]);
  return (
    <Box>
      <Grid container pb={2}>
        <Grid item xs={2}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Grid item xs={2}>
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={3}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            sx={{ width: { xl: '18rem', lg: '15rem' } }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
      </Grid>
      <Grid container pb={1}>
        <Grid item xs={2}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={3}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box>
        <ResponsiveTypography py={2}>
          Application Permission
        </ResponsiveTypography>
        <ManageRestriction />
      </Box>
      <Box display="flex" width="100%" mt={1} gap="1rem">
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          onClick={() => dispatch(redirectToEditScreen(true))}
        >
          Skip
        </Button>
        <Cancel />
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(configureNewGroup())}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </Box>
  );
}

export default GroupSettings;
