import React, { useEffect } from 'react';
import {
  Box,
  MenuItem,
  Grid,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectChangeEvent } from '@mui/material/Select';
import { AppDispatch, RootState } from '../../../Redux/store';
import { ResponsiveTypography } from '../../../styles/inputLabel';
import { lightBlueBackgroundButtonStyles } from '../../../styles/button';
import { StyledSelect, menuItemCSS } from '../../../styles/select';
import { StyledTextField } from '../../../styles/textField';
import { isValidURL } from '../../../utils/utility';
import {
  updateAuthenticationName,
  updateIssuer,
  updateLoginURL,
  updateLogoutURL,
  updateBindingType,
  updateX509Certificate,
  draftAuthenticationSource,
  updateAuthenticationSource,
  addNewAuthenticationSource,
  updateOpenDrawer,
} from '../../../Redux/Slices/Application/AuthenticationSource/authenticationSourceSlice';
import { buttonIconStyles } from '../../../styles/icon';
import AuthenticationSourceConfiguration from '../../../pages/AuthenticationSource/AddAuthenticationSource';

function AuthenticationSourceCommon() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    issuer,
    loginURL,
    logoutURL,
    X509Certificate,
    selectedSource,
    configuredAuthenticationSource,
    bindingType,
  } = useSelector((state: RootState) => state.authenticationSource);

  const switchAuthenticationSource = (event: SelectChangeEvent) => {
    if (event.target.value !== 'Add Authentication Source') {
      configuredAuthenticationSource.forEach((source: any) => {
        if (source._id === event.target.value) {
          dispatch(updateAuthenticationSource(source));
        }
      });
    } else if (event.target.value === 'Add Authentication Source') {
      dispatch(addNewAuthenticationSource());
    }
  };

  useEffect(() => {
    async function getDraftedConfiguration() {
      dispatch(draftAuthenticationSource());
    }
    getDraftedConfiguration();
  }, [configuredAuthenticationSource]);

  const handleEditAuthenticationSource = (id: any) => {
    configuredAuthenticationSource.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updateAuthenticationSource(element));
        dispatch(updateOpenDrawer(true));
      }
    });
  };

  return (
    <Box>
      <AuthenticationSourceConfiguration />
      <Grid
        container
        sx={{
          rowGap: {
            xxl: 3,
            xl: 2,
            lg: 1,
            md: 1,
          },
          alignItems: 'center',
        }}
      >
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>Authentication Source</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="1rem"
            width="100%"
          >
            <StyledSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              name="authentication_id"
              label=""
              onChange={switchAuthenticationSource}
              value={selectedSource}
              style={{ flex: 1 }}
              width="60%"
            >
              {configuredAuthenticationSource?.map((source) => (
                <MenuItem
                  key={source?._id}
                  value={source?._id}
                  sx={menuItemCSS}
                >
                  {source.identifier}
                </MenuItem>
              ))}
              <MenuItem value="Add Authentication Source" sx={menuItemCSS}>
                Add Authentication Source
              </MenuItem>
            </StyledSelect>
            <Button
              sx={{ ...lightBlueBackgroundButtonStyles, width: '5rem' }}
              disabled={
                !selectedSource
                || selectedSource === 'Add Authentication Source'
              }
              onClick={() => handleEditAuthenticationSource(selectedSource)}
            >
              <EditOutlinedIcon sx={buttonIconStyles} />
              &nbsp;&nbsp;Edit
            </Button>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>Authentication Name</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            name="identifier"
            disabled={selectedSource !== 'Add Authentication Source'}
            value={identifier || ''}
            onChange={(e: any) => dispatch(updateAuthenticationName(e.target.value))}
            placeholder="Authentication Name"
          />
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>IdP Entity ID or Issuer</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            name="issuer"
            disabled={selectedSource !== 'Add Authentication Source'}
            value={issuer || ''}
            onChange={(e: any) => dispatch(updateIssuer(e.target.value))}
            placeholder="IDP Entity ID or Issuer"
          />
          <Typography
            display={!issuer || isValidURL(issuer) ? 'none' : 'flex'}
            sx={{ fontSize: '10px', color: 'red' }}
          >
            Please provide a valid url.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>SAML Login URL</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            name="loginURL"
            disabled={selectedSource !== 'Add Authentication Source'}
            value={loginURL || ''}
            onChange={(e: any) => dispatch(updateLoginURL(e.target.value))}
            placeholder="SAML Login URL"
          />
          <Typography
            display={!loginURL || isValidURL(loginURL) ? 'none' : 'flex'}
            sx={{ fontSize: '10px', color: 'red' }}
          >
            Please provide a valid url.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>SAML Logout URL</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            name="logoutURL"
            disabled={selectedSource !== 'Add Authentication Source'}
            value={logoutURL || ''}
            onChange={(e: any) => dispatch(updateLogoutURL(e.target.value))}
            placeholder="SAML Logout URL"
          />
          <Typography
            display={!logoutURL || isValidURL(logoutURL) ? 'none' : 'flex'}
            sx={{ fontSize: '10px', color: 'red' }}
          >
            Please provide a valid url.
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>
              Select Binding Type for SSO Request
            </ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="bindingType"
            value={bindingType || ''}
            onChange={(event: any) => dispatch(updateBindingType(event.target.value))}
            sx={{ py: { xl: 0, lg: 0, md: 0 } }}
          >
            <FormControlLabel
              value="HttpRedirect"
              control={<Radio size="small" />}
              label={(
                <ResponsiveTypography>
                  HTTP-Redirect Binding
                </ResponsiveTypography>
              )}
              disabled={selectedSource !== 'Add Authentication Source'}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: {
                    xl: '13px !important',
                    lg: '11px !important',
                    md: '8px !important',
                  },
                },
              }}
            />
            <FormControlLabel
              value="HttpPost"
              control={<Radio size="small" />}
              label={
                <ResponsiveTypography>HTTP-Post Binding</ResponsiveTypography>
              }
              disabled={selectedSource !== 'Add Authentication Source'}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: {
                    xl: '13px !important',
                    lg: '11px !important',
                    md: '8px !important',
                  },
                },
              }}
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" gap={{ xl: '0.25rem', lg: '0.2rem' }}>
            <ResponsiveTypography>X.509 Certificate</ResponsiveTypography>
            <ResponsiveTypography color="red">*</ResponsiveTypography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <TextField
            required
            fullWidth
            multiline
            name="X509Certificate"
            placeholder="X509Certificate"
            rows={3}
            maxRows={3}
            value={X509Certificate || ''}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: {
                  xxl: '15px !important',
                  xl: '13px !important',
                  lg: '10px !important',
                  md: '8px !important',
                },
                height: {
                  xl: '4rem !important',
                  lg: '3rem !important',
                  md: '3.5rem !important',
                },
                padding: {
                  xl: 0,
                  lg: '4px 7px !important',
                  md: '4px 7px !important',
                  sm: '4px 7px !important',
                },
              },
            }}
            disabled={selectedSource !== 'Add Authentication Source'}
            onChange={(e: any) => dispatch(updateX509Certificate(e.target.value))}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AuthenticationSourceCommon;
