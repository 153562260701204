import React from 'react';
import { Fade, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Add this import
import AddLogo from '../../assets/images/add.svg';
import MainLayout from '../../components/MainLayout';
import { ResponsiveHeading } from '../../styles/heading';
import { ResponsiveImage } from '../../styles/app-logo';
import { apps } from '../Application/app';
import { mediumIconStyles } from '../../styles/icon';

function Applications() {
  const navigate = useNavigate(); // Create the navigate function

  return (
    <MainLayout>
      <Fade in timeout={500}>
        <Box p={5}>
          <ResponsiveHeading>Applications</ResponsiveHeading>
          <Grid container spacing={2} py={3}>
            {Object.values(apps).map((app) => (
              <Grid item xs={3} key={app.title}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    backgroundColor: '#F7F7F7',
                    borderRadius: '10px',
                    padding: 3,
                  }}
                >
                  <ResponsiveImage src={app.logo} alt={app.title} />
                  <Box
                    component="img"
                    src={AddLogo}
                    alt="add"
                    sx={mediumIconStyles}
                    onClick={() => navigate(app.path)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </MainLayout>
  );
}

export default Applications;
