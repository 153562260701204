import React from 'react';
import { Box, Button } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useDispatch } from 'react-redux';
import { ResponsiveTypography } from '../../../styles/inputLabel';
import { lightBackgroundButtonStyles } from '../../../styles/button';
import { buttonIconStyles } from '../../../styles/icon';
import { downloadFederationScript } from '../../../Redux/Slices/Application/applicationHandler';
import { AppDispatch } from '../../../Redux/store';

function OfficeInfo() {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="1rem"
      borderBottom="1px solid #E3E3E3"
      py={2}
    >
      <ResponsiveTypography>Federation Script</ResponsiveTypography>
      <Box display="flex" flexDirection="row" gap="1rem">
        <Button
          sx={{
            ...lightBackgroundButtonStyles,
            width: { xl: '10rem !important', lg: '8rem !important' },
          }}
          startIcon={(
            <DownloadOutlinedIcon
              sx={{ ...buttonIconStyles, color: '#2D3748' }}
            />
          )}
          onClick={() => dispatch(downloadFederationScript())}
        >
          Download
        </Button>
      </Box>
    </Box>
  );
}

export default OfficeInfo;
