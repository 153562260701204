import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Button,
  TextField,
  IconButton,
  Typography,
  Link,
  FormControl,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from '../../components/ErrorMessage';
import MiniOrangelogo from '../../components/MiniOrangeLogo';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const initialized = useRef(false);
  const submitButtonRef = useRef<any>(null);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const status = params.get('sign-up');
      if (status === 'success') {
        toast.success('SignUp Successful, Please try logging in!');
      }
    }
  }, []);

  const [loginInputFields, setLoginInputFields] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState({
    email: false,
    password: false,
    incorrectCredentials: false,
  });

  const onChange = (event: any) => {
    const { name, value } = event.target;
    setError((prevValue) => ({
      ...prevValue,
      [name]: false,
    }));
    setLoginInputFields((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const loginUser = () => {
    if (!loginInputFields.email && !loginInputFields.password) {
      setError({
        email: true,
        password: true,
        incorrectCredentials: false,
      });
    } else if (!loginInputFields.email) {
      setError({
        email: true,
        password: false,
        incorrectCredentials: false,
      });
    } else if (!loginInputFields.password) {
      setError({ email: false, password: true, incorrectCredentials: false });
    } else {
      const toastId = toast.loading('Logging you in...');
      const params = new URLSearchParams(new URL(window.location.href).search);
      const source = params.get('source');
      axios
        .post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/user-login`, {
          email: loginInputFields.email,
          password: loginInputFields.password,
          source,
        })
        .then(async (res) => {
          const timestamp = new Date().getTime();
          const exp = timestamp + 60 * 60 * 24 * 1000 * 7;
          document.cookie = `token=${res.data.token};expires=${exp};path=/`;
          toast.update(toastId, {
            render: 'Login successfully.',
            type: 'success',
            autoClose: 2000,
            closeButton: true,
            isLoading: false,
          });
          navigate('/dashboard');
        })
        .catch((err) => {
          if (
            err.response
            && err.response.data
            && err.response.data.errorMessage
          ) {
            toast.update(toastId, {
              render: 'Something Went Wrong!',
              type: 'error',
              autoClose: 2000,
              closeButton: true,
              isLoading: false,
            });
            setError({
              email: false,
              password: false,
              incorrectCredentials: true,
            });
          }
        });
    }
  };

  return (
    <Box
      component="span"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        py: { xs: '2rem', md: '0' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          padding: { md: '1rem' },
          justifyContent: { xs: 'center', md: 'start' },
        }}
      >
        <MiniOrangelogo />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '4rem',
        }}
      >
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            mx: { xs: '1rem', md: '0' },
          }}
        >
          <Typography
            variant="h4"
            align="center"
            color="#3D3D3D"
            sx={{ '@media (max-width:600px)': { fontSize: '1.5rem' } }}
          >
            Welcome Back, Login.
          </Typography>
          <Typography variant="subtitle1" align="center" color="#717171">
            Log in with your data that entered during your registration
          </Typography>
          {error.incorrectCredentials && (
            <ErrorMessage message="Authentication failed. Please check your email and password." />
          )}
          <Box>
            <Typography variant="subtitle2" color="#5F636D">
              Email
            </Typography>
            <TextField
              required
              fullWidth
              size="small"
              id="email"
              name="email"
              onChange={onChange}
            />
            {error.email && (
              <Typography sx={{ color: 'red' }}>
                Please enter an email address.
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2" color="#5F636D">
              Password
            </Typography>
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <OutlinedInput
                id="password"
                name="password"
                onChange={onChange}
                size="small"
                type={showPassword ? 'text' : 'password'}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
                required
              />
            </FormControl>
            {error.password && (
              <Typography color="#FF5656">Enter a password.</Typography>
            )}
          </Box>
          <Typography color="#717171" variant="subtitle2">
            Forgot Password?&nbsp;
            <Link
              style={{ textDecoration: 'none' }}
              href="https://login.xecurify.com/moas/idp/resetpassword"
              target="_blank"
            >
              Reset
            </Link>
          </Typography>
          <ToastContainer />
          <Button
            ref={submitButtonRef}
            onClick={() => loginUser()}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#2D3748',
              textTransform: 'none',
              '&:hover': { background: '#2D3748' },
            }}
          >
            LOGIN
          </Button>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography color="#717171" variant="subtitle2">
              Don’t have an account?&nbsp;
              <Link style={{ textDecoration: 'none' }} href="/getting-started">
                Sign up
              </Link>
            </Typography>
          </Box>
          {/* <Divider>or</Divider>
          <Button
            fullWidth
            variant="contained"
            sx={{
              border: '1px solid rgb(204, 204, 204)',
              borderRadius: '3em',
              textTransform: 'none',
              backgroundColor: '#FFFFFF',
              color: '#5F636D',
              boxShadow: 'none',
              '&:hover': { background: 'none' },
            }}
          >
            <img src={googleLogo} height="20" alt="google-logo" />
            &nbsp;&nbsp; Sign in with Google
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
}
