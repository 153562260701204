import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  IconButton,
  Popover,
  Box,
  Button,
  Grid,
  TextField,
  Divider,
} from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { useDispatch, useSelector } from 'react-redux';
import { mediumIconStyles } from '../styles/icon';
import { StyledTextField } from '../styles/textField';
import { ResponsiveTypography } from '../styles/inputLabel';
import { SmallHeading } from '../styles/smallHeading';
import { cyanBlueBackgroundButtonStyles } from '../styles/button';
import { AppDispatch, RootState } from '../Redux/store';
import { submitCustomerSupportForm } from '../Redux/Slices/Support/supportHandler';
import { updateSupportForm } from '../Redux/Slices/Support/support';

function SupportForm() {
  const dispatch = useDispatch<AppDispatch>();
  const { email, message } = useSelector(
    (state: RootState) => state.customerSupport,
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    async function updateURL() {
      dispatch(
        updateSupportForm({
          target: 'url',
          value: window.location.href,
        }),
      );
    }
    updateURL();
  }, []);
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        mr: 2,
        mb: 2,
      }}
    >
      <Tooltip title="Support" placement="top" arrow>
        <IconButton
          onClick={handleClick}
          sx={{
            backgroundColor: '#253858',
            borderRadius: '50%',
            padding: 1,
            '&:hover': {
              backgroundColor: '#2f4d73',
            },
          }}
        >
          <ContactSupportIcon sx={{ ...mediumIconStyles, color: '#fff' }} />
        </IconButton>
      </Tooltip>
      <Popover
        id="support-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 2,
            borderRadius: 2,
            backgroundColor: 'white',
            width: '25rem',
          }}
        >
          <Box pb={1}>
            <SmallHeading>Support</SmallHeading>
            <ResponsiveTypography>
              Please fill out the form below to contact our support team.
            </ResponsiveTypography>
          </Box>
          <Divider />
          <Grid
            container
            sx={{
              rowGap: {
                xxl: 3,
                xl: 2,
                lg: 1,
                md: 1,
              },
            }}
            pt={2}
          >
            <Grid item xs={12}>
              <ResponsiveTypography variant="subtitle2">
                Email
              </ResponsiveTypography>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                name="email"
                onChange={(event: any) => dispatch(
                  updateSupportForm({
                    target: 'email',
                    value: event.target.value,
                  }),
                )}
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              <ResponsiveTypography variant="subtitle2">
                Message
              </ResponsiveTypography>
              <TextField
                required
                fullWidth
                multiline
                name="message"
                rows={3}
                maxRows={3}
                sx={{
                  '& .MuiInputBase-input': {
                    fontSize: {
                      xl: '15px !important',
                      lg: '10px !important',
                      md: '10px',
                    },
                    height: {
                      xl: '4rem !important',
                      lg: '3rem !important',
                      md: '3rem !important',
                    },
                    padding: {
                      xl: 0,
                      lg: '4px 7px !important',
                      md: '4px 7px !important',
                    },
                  },
                }}
                value={message}
                onChange={(event: any) => dispatch(
                  updateSupportForm({
                    target: 'message',
                    value: event.target.value,
                  }),
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ ...cyanBlueBackgroundButtonStyles, width: '6rem' }}
                onClick={() => dispatch(submitCustomerSupportForm())}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  );
}

export default SupportForm;
