import React, { useState } from 'react';
import {
  Box, Switch,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCachingFeature,
} from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubLabel } from '../../../../styles/sublabel';
import { SubTitle } from '../../../../styles/subtitle';

function CachingPerformance() {
  const dispatch = useDispatch();
  const cachingFeature = useSelector((state: RootState) => state.proxySettings.CachingFeature);
  const [hour, setHour] = useState(24);
  const [minutes, setMinutes] = useState(0);
  const [error] = useState('Cache Time Exceeded');

  const handleCachingToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCachingFeature({
      enable: event.target.checked,
      cachingTime: cachingFeature.cachingTime,
    }));
  };

  const setCachingTime = (event: any) => {
    const cacheTime = parseInt(event.target.value, 10);
    dispatch(updateCachingFeature({
      enable: cachingFeature.enable,
      cachingTime: cacheTime,
    }));
    setHour(Math.floor(cacheTime / 60));
    setMinutes(cacheTime % 60);
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem', overflowY: 'auto', overflowX: 'hidden' }}
      height="40vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="90%"
        sx={{ gap: '0.5rem' }}
        px={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>Enable Cache</ResponsiveTypography>
          <Switch
            checked={cachingFeature.enable}
            onChange={handleCachingToggle}
          />
        </Box>
        <SubLabel>
          Use these settings to improve the performance of your page by caching
          the data. miniOrange will save the data in cache from original source
          after the first request. You can always clear the cache by using Purge
          Cache option.
        </SubLabel>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="90%"
        alignItems="center"
        px={2}
      >
        <Box>
          <SubTitle>
            Cache Expire TTL (in minutes)
          </SubTitle>
          <SubTitle>Max age: 168 Hours</SubTitle>
        </Box>
        <Box width="60%">
          <StyledTextField
            fullWidth
            size="small"
            onChange={setCachingTime}
            value={cachingFeature.cachingTime}
          />
          <Box display="flex" flexDirection="column">
            <SubTitle>
              miniOrange will automatically clear your cache after&nbsp;
              {hour}
              &nbsp;Minutes&nbsp;
              {minutes}
              &nbsp;Seconds
            </SubTitle>
            {hour > 168 && (
              <ResponsiveTypography variant="caption" color="red">
                {error}
              </ResponsiveTypography>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="90%"
        sx={{ gap: '0.5rem', backgroundColor: '#F6F6F6' }}
        p={2}
      >
        <Box display="flex" flexDirection="column">
          <ResponsiveTypography>Never Cache URL(s)</ResponsiveTypography>
          <SubLabel>
            Sensitive pages like custom login/logout URLs should be excluded
            from cache.
          </SubLabel>
        </Box>
        <Box display="flex" flexDirection="column">
          <SubTitle>
            Specify URLs of pages or posts that should never be cached(one per
            line)
          </SubTitle>
          <StyledTextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue="Default Value"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CachingPerformance;
