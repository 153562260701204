import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ResponsiveHeading = styled(Typography)(({ theme }: any) => ({
  color: 'title',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.6rem',
  },
}));
