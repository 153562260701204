import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import MainLayout from '../../components/MainLayout';
import {
  currentActiveTenantDetails,
  getUser,
  DomainDetails,
  getCookie,
} from '../../utils/utility';
import { StyledTextField } from '../../styles/textField';
import { ResponsiveTypography } from '../../styles/inputLabel';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../styles/button';
import { ResponsiveHeading } from '../../styles/heading';

function TenantSettings() {
  const [image, setImage] = useState('');
  const [imageurl, setImageURL] = useState('');
  const [proxyURL, setProxyURL] = useState('');
  const [tenantSettingsDetails, setTenantSettingsDetails] = useState({
    baseDomain: null,
    key: null,
    cert: null,
  });
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/svg+xml',
    ];
    if (!validImageTypes.includes(fileType)) {
      toast.error('Invalid Image format!');
      return;
    }
    const fileSize = file.size;
    if (fileSize > 3 * 1024 * 1024) {
      toast.error('File Size Exceeding 3MB!');
      return;
    }
    setImage(file);
  };

  useEffect(() => {
    async function getTenantDetails() {
      const tenantDetails: any = await currentActiveTenantDetails();
      setImageURL(tenantDetails?.branding_logo_url);
      setProxyURL(tenantDetails?.url);
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        baseDomain: tenantDetails?.domain,
      }));
    }
    async function getCurrentDomainDetails() {
      const domainDetails: any = await DomainDetails();
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        key: domainDetails?.ssl_protocol?.key,
      }));
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        cert: domainDetails?.ssl_protocol?.cert,
      }));
    }
    getTenantDetails();
    getCurrentDomainDetails();
  }, []);

  const uploadTenantBrandingLogo = async () => {
    const formData = new FormData();
    const user = await getUser();
    if (!image) {
      toast.error('No Image Selected.');
      return;
    }
    const token = getCookie('token');
    formData.append('branding-logo', image);
    formData.append('customer_id', user.customer_id);
    formData.append('current_active_tenant_id', user.current_active_tenant_id);
    formData.append('_id', user.id);
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/upload-tenant-branding-logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token,
          },
        },
      )
      .then((response) => {
        setImageURL(response?.data?.response?.Location);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPLOAD_BRAND_IMAGE_ERROR);
      });
  };

  const saveTenantSettings = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    setTenantSettingsDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const restoreDefaultTenantSettings = async () => {
    const user = await getUser();
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/restore-default-tenant-settings`,
        {
          user,
          token,
        },
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast.error(
          process.env.REACT_APP_RESTORE_DEFAULT_TENANT_SETTINGS_ERROR,
        );
      });
  };

  const updateTenantSettings = async () => {
    const token = await getCookie('token');
    if (
      !tenantSettingsDetails.key
      || !tenantSettingsDetails.cert
      || !tenantSettingsDetails.baseDomain
    ) {
      toast.error('Please provide all necessary information');
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/tenant-settings`,
        {
          tenantSettingsDetails,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        toast.success(response?.data?.message);
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" p={3}>
        <ToastContainer />
        <Box display="flex" justifyContent="space-between">
          <ResponsiveHeading>Tenant Settings</ResponsiveHeading>
        </Box>
        <Box display="flex" width="100%">
          <Box
            p={3}
            border="1px solid #EBEBEB"
            borderRadius="10px"
            sx={{ width: '70%' }}
            mt={2}
          >
            <Grid container rowGap={2}>
              <Grid item xs={5}>
                <ResponsiveTypography> Branding Logo</ResponsiveTypography>
              </Grid>
              <Grid item xs={7} alignItems="center">
                <Box display="flex" gap="1rem">
                  <StyledTextField
                    id="branding-logo"
                    type="file"
                    size="small"
                    sx={{ width: '30rem' }}
                    onChange={handleFileChange}
                  />
                  <Button
                    sx={cyanBlueBackgroundButtonStyles}
                    onClick={uploadTenantBrandingLogo}
                  >
                    Upload
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  {imageurl && (
                    <img src={imageurl} alt="branding-logo" width="200" />
                  )}
                </Box>
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography> Base Domain</ResponsiveTypography>
              </Grid>
              <Grid xs={7} item alignItems="center">
                <StyledTextField
                  id="base-domain"
                  size="small"
                  name="baseDomain"
                  value={tenantSettingsDetails.baseDomain}
                  onChange={saveTenantSettings}
                />
              </Grid>
              <Grid xs={5} item>
                <ResponsiveTypography>Proxy URL</ResponsiveTypography>
              </Grid>
              <Grid xs={7} item alignItems="center">
                <StyledTextField
                  id="proxy-url"
                  size="small"
                  fullWidth
                  value={proxyURL}
                  onChange={saveTenantSettings}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography>Key Value</ResponsiveTypography>
              </Grid>
              <Grid item xs={7} alignItems="center">
                <TextField
                  id="outlined-multiline-static"
                  name="key"
                  multiline
                  rows={4}
                  fullWidth
                  value={tenantSettingsDetails.key}
                  onChange={saveTenantSettings}
                />
              </Grid>
              <Grid item xs={5}>
                <ResponsiveTypography> Certificate Value</ResponsiveTypography>
              </Grid>
              <Grid item xs={7} alignItems="center">
                <TextField
                  id="outlined-multiline-static"
                  name="cert"
                  multiline
                  rows={4}
                  fullWidth
                  value={tenantSettingsDetails.cert}
                  onChange={saveTenantSettings}
                />
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box display="flex" alignItems="center" gap="1rem">
                <Button
                  sx={{ ...cyanBlueBackgroundButtonStyles, width: '6rem' }}
                  onClick={updateTenantSettings}
                >
                  Submit
                </Button>
                <Button sx={{ ...lightBackgroundButtonStyles, width: '6rem' }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
          <Box py={3} pl={5} width="30%">
            <Box
              display="flex"
              flexDirection="column"
              borderBottom="1px solid #EBEBEB"
            >
              <ResponsiveTypography>Restore</ResponsiveTypography>
              <Box my={2}>
                <Button
                  sx={lightBackgroundButtonStyles}
                  onClick={restoreDefaultTenantSettings}
                >
                  Restore Default
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default TenantSettings;
