import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Toaster } from 'react-hot-toast';
import AuthenticationSourceCommon from '../../../../components/CASBApp/AuthenticationSource/AuthenticationSource';
import { AppDispatch } from '../../../../Redux/store';
import { updateApplicationAuthenticationSource } from '../../../../Redux/Slices/Application/AuthenticationSource/authenticationSourceHandler';
import { cyanBlueBackgroundButtonStyles } from '../../../../styles/button';
import { buttonIconStyles } from '../../../../styles/icon';
import Cancel from '../../../../components/cancelButton';

function AuthenticationSource() {
  const dispatch = useDispatch<AppDispatch>();
  const [saveClicked, setSaveClicked] = useState(false);
  const updateApplicationAuthentication = async () => {
    setSaveClicked(true);
    await dispatch(updateApplicationAuthenticationSource());
    setSaveClicked(false);
  };
  return (
    <Box>
      <Toaster />
      <AuthenticationSourceCommon />
      <Box display="flex" width="100%" gap="1rem" pt={2}>
        <Cancel />
        <Button
          variant="text"
          disabled={saveClicked}
          sx={cyanBlueBackgroundButtonStyles}
          onClick={updateApplicationAuthentication}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </Box>
  );
}

export default AuthenticationSource;
