import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  AppBar,
  ListItem,
  ListItemButton,
  Typography,
  CssBaseline,
  Tooltip,
  Avatar,
} from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import SyncIcon from '@mui/icons-material/Sync';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import HelpIcon from '@mui/icons-material/Help';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import LaptopWindowsOutlinedIcon from '@mui/icons-material/LaptopWindowsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { LogoutOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveTypography } from '../styles/inputLabel';
import MiniOrangeLogo from './MiniOrangeLogo';
import MiniOrangeShortLogo from './MiniOrangeShortLogo';
import {
  getAllTenants,
  userSwitchTenant,
  getActiveTenant,
  getUser,
} from '../utils/utility';
import {
  cyanBlueBackgroundButtonStyles,
  zeroPaddingAutoWidthButton,
} from '../styles/button';
import { mediumIconStyles, smallIconStyles } from '../styles/icon';
import { AppDispatch, RootState } from '../Redux/store';
import { updateCustomer } from '../Redux/Slices/Customer/customer';
import SupportForm from './supportForm';

interface MainLayoutProps {
  children: React.ReactElement;
}

function ListItemComponent({
  icon, text, to, drawer,
}: any) {
  return (
    <Link to={to} style={{ textDecoration: 'none', color: '#9A9A9A' }}>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            padding: { xl: '2 !important' },
            '&:hover': {
              backgroundColor: '#E0F2FF',
              cursor: 'pointer',
            },
            borderRadius: '10px',
          }}
        >
          {icon}
          &nbsp;&nbsp;
          <ResponsiveTypography
            variant="subtitle2"
            color="#171821"
            sx={{ display: drawer ? 'block' : 'none' }}
          >
            {text}
          </ResponsiveTypography>
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default function MainLayout({
  children,
}: MainLayoutProps): React.ReactElement {
  const dispatch = useDispatch<AppDispatch>();
  const alphabetColors = [
    { letter: 'a', color: '#FF69B4' },
    { letter: 'b', color: '#33CC33' },
    { letter: 'c', color: '#6666FF' },
    { letter: 'd', color: '#FF9900' },
    { letter: 'e', color: '#0099CC' },
    { letter: 'f', color: '#CC0099' },
    { letter: 'g', color: '#33FFFF' },
    { letter: 'h', color: '#666600' },
    { letter: 'i', color: '#FFCC00' },
    { letter: 'j', color: '#009933' },
    { letter: 'k', color: '#CC6600' },
    { letter: 'l', color: '#33CC00' },
    { letter: 'm', color: '#6666CC' },
    { letter: 'n', color: '#FF0066' },
    { letter: 'o', color: '#0099FF' },
    { letter: 'p', color: '#CC0033' },
    { letter: 'q', color: '#33FF99' },
    { letter: 'r', color: '#666633' },
    { letter: 's', color: '#FFCC33' },
    { letter: 't', color: '#009966' },
    { letter: 'u', color: '#CC0099' },
    { letter: 'v', color: '#33FFFF' },
    { letter: 'w', color: '#666600' },
    { letter: 'x', color: '#FFCC00' },
    { letter: 'y', color: '#009933' },
    { letter: 'z', color: '#CC6600' },
  ];
  const { email } = useSelector((state: RootState) => state.customer);
  const firstLetter = email.charAt(0).toLowerCase();
  const color = alphabetColors.find((item) => item.letter === firstLetter)?.color
    ?? '#defaultColor';
  const [allTenants, setAllTenants] = useState<any[]>([]);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [drawer, setDrawer] = useState(true);
  const [drawerWidth, setDrawerWidth] = useState({
    xs: 180,
    sm: 200,
    md: 230,
    lg: 250,
    xl: 280,
  });

  const navigate = useNavigate();

  const listItems = [
    {
      icon: <LaptopWindowsOutlinedIcon sx={smallIconStyles} />,
      text: 'Dashboard',
      to: '/dashboard',
      drawer,
    },
    {
      icon: <DashboardOutlinedIcon sx={smallIconStyles} />,
      text: 'Applications',
      to: '/applications',
      drawer,
    },
    {
      icon: <VerifiedUserOutlinedIcon sx={smallIconStyles} />,
      text: 'Manage Policy',
      to: '/manage-policy',
      drawer,
    },
    {
      icon: <LockOpenOutlinedIcon sx={smallIconStyles} />,
      text: 'Authentication Source',
      to: '/configure/authentication-source',
      drawer,
    },
    {
      icon: <ListOutlinedIcon sx={smallIconStyles} />,
      text: 'Secure Cloud App',
      to: '/configure/proxy',
      drawer,
    },
  ];

  const handleTenantSwitch = (event: SelectChangeEvent) => {
    const tenantName = event.target.value as string;
    setSelectedTenant(tenantName);
    allTenants.forEach(async (tenant) => {
      if (tenantName === tenant.tenant_name) {
        const tenantID = tenant._id;
        await userSwitchTenant({ tenantID }).then(async (response) => {
          const timestamp = new Date().getTime();
          const exp = timestamp + 60 * 60 * 24 * 1000 * 7;
          document.cookie = `token=${response};expires=${exp};path=/`;
          await window.location.reload();
        });
      }
    });
  };

  useEffect(() => {
    async function getAllTenantsInfo() {
      getAllTenants().then((data: any) => {
        setAllTenants(data);
      });
    }
    async function getUserDetails() {
      const user: any = await getUser();
      dispatch(updateCustomer(user));
    }
    getAllTenantsInfo();
    getUserDetails();
  }, []);

  useEffect(() => {
    async function getCurrentActiveTenant() {
      const currentActiveTenant = getActiveTenant();
      currentActiveTenant.then((result) => {
        allTenants.forEach((tenant) => {
          if (tenant._id === result) {
            setSelectedTenant(tenant.tenant_name);
          }
        });
      });
    }
    getCurrentActiveTenant();
  }, [allTenants]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const logoutUser = () => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    navigate('/login');
  };

  const handleHideSidebar = () => {
    setDrawer(false);
    setDrawer(false);
    setDrawerWidth({
      xs: 50,
      sm: 60,
      md: 65,
      lg: 70,
      xl: 75,
    });
  };

  const handleShowSidebar = () => {
    setDrawer(true);
    setDrawerWidth({
      xs: 180,
      sm: 200,
      md: 230,
      lg: 250,
      xl: 280,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        boxShadow: 'none !important',
      }}
    >
      <CssBaseline />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        py={1}
        sx={{
          height: '100%',
          width: {
            xs: drawerWidth.xs,
            sm: drawerWidth.sm,
            md: drawerWidth.md,
            lg: drawerWidth.lg,
            xl: drawerWidth.xl,
          },
          transition: 'width 0.3s',
          borderRight: '1px solid #D9D9D9',
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pl={2}
            pr={drawer ? 0 : 2}
            alignItems="center"
          >
            {drawer ? <MiniOrangeLogo /> : <MiniOrangeShortLogo />}
            {drawer ? (
              <ChevronLeftIcon
                onClick={handleHideSidebar}
                sx={{ pointer: 'cursor !important', color: '#EAEAEA' }}
              />
            ) : (
              <ChevronRightIcon
                onClick={handleShowSidebar}
                sx={{ pointer: 'cursor !important', color: '#EAEAEA' }}
              />
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: {
                xl: '3rem !important',
                lg: '2rem !important',
                md: '2rem !important',
              },
              gap: {
                xl: '0.5rem !important',
                lg: '0.25rem !important',
                md: '0.10rem !important',
              },
            }}
            px={1}
          >
            {listItems.map((item) => (
              <ListItemComponent {...item} />
            ))}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          pl={2}
          py={2}
          borderTop="1px solid #EBEBEB"
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: '0.5rem',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={logoutUser}
          >
            <LogoutOutlined sx={smallIconStyles} />
            <ResponsiveTypography display={drawer ? 'flex' : 'none'}>
              Logout
            </ResponsiveTypography>
          </Box>
          <Typography
            display={drawer ? 'flex' : 'none'}
            sx={{
              fontSize: { xl: '12px', lg: '10px', md: '8px' },
              color: '#636363',
            }}
          >
            Powered by
            <span style={{ paddingLeft: '3px', color: '#F07D2C' }}>
              miniOrange
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <AppBar
          style={{
            position: 'sticky',
            background: '#ffffff',
            borderBottom: '1px solid #D9D9D9',
            boxShadow: 'none',
          }}
        >
          <Box
            display={{ xs: 'none', lg: 'flex' }}
            alignItems="center"
            justifyContent="end"
            gap={{ xl: '1rem !important', lg: '0.75rem !important' }}
            px={{ xl: '1rem !important', lg: '0.75rem !important' }}
            py={{ xl: '0.5rem !important', lg: '0.25rem !important' }}
          >
            <Button sx={zeroPaddingAutoWidthButton}>
              <Tooltip title="Support" placement="top" arrow>
                <HelpIcon
                  sx={mediumIconStyles}
                  onClick={() => window.open('https://www.miniorange.com/contact', '_blank')}
                />
              </Tooltip>
            </Button>
            <Button
              sx={zeroPaddingAutoWidthButton}
              onClick={() => navigate('/settings')}
            >
              <Tooltip title="Tenant Settings" placement="top" arrow>
                <SettingsIcon sx={mediumIconStyles} />
              </Tooltip>
            </Button>
            <FormControl>
              <Select
                value={selectedTenant !== '' ? selectedTenant : 'default'}
                renderValue={(value) => (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <SyncIcon sx={{ ...smallIconStyles, color: 'white' }} />
                    &nbsp;
                    <ResponsiveTypography>
                      Switch Tenant : &nbsp;&nbsp;
                      {selectedTenant !== '' ? value : selectedTenant}
                    </ResponsiveTypography>
                  </span>
                )}
                sx={{
                  ...cyanBlueBackgroundButtonStyles,
                  border: 'none !important',
                  padding: '0rem !important',
                  height: '2rem',
                  width: { xl: '14rem !important', lg: '11rem !important' },
                }}
                onChange={handleTenantSwitch}
                inputProps={{ IconComponent: () => null }}
              >
                {allTenants.map((tenant) => (
                  <MenuItem value={tenant.tenant_name} key={tenant.tenant_name}>
                    {tenant.tenant_name}
                  </MenuItem>
                ))}
                <Link to="/add/tenant" style={{ textDecoration: 'none' }}>
                  <MenuItem
                    value="Add New Tenant"
                    key="Add New Tenant"
                    sx={{ textDecoration: 'none' }}
                  >
                    View All Tenants
                  </MenuItem>
                </Link>
              </Select>
            </FormControl>
            <Button sx={zeroPaddingAutoWidthButton} onClick={handleClick}>
              <Tooltip title="Profile" placement="top" arrow>
                <Avatar
                  sx={{
                    width: {
                      xxl: 40,
                      xl: 30,
                      lg: 25,
                      md: 20,
                    },
                    height: {
                      xxl: 40,
                      xl: 30,
                      lg: 25,
                      md: 20,
                    },
                    backgroundColor: color,
                    fontSize: {
                      xxl: 25,
                      xl: 20,
                      lg: 15,
                      md: 10,
                    },
                  }}
                >
                  {firstLetter.toUpperCase()}
                </Avatar>
              </Tooltip>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box width="15rem" pb={3}>
                <Box
                  width="100%"
                  py={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    sx={{
                      width: {
                        xxl: 100,
                        xl: 90,
                        lg: 80,
                        md: 70,
                      },
                      height: {
                        xxl: 100,
                        xl: 90,
                        lg: 80,
                        md: 70,
                      },
                      backgroundColor: color,
                      fontSize: {
                        xxl: 80,
                        xl: 70,
                        lg: 60,
                        md: 50,
                      },
                    }}
                  >
                    {firstLetter.toUpperCase()}
                  </Avatar>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <ResponsiveTypography>{email}</ResponsiveTypography>
                  <Button onClick={() => navigate('/settings')}>
                    Tenant Settings
                  </Button>
                  <Button onClick={logoutUser}>Logout</Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </AppBar>
        <Box display="flex" position="relative" height="100vh">
          <Toaster />
          <Box sx={{ width: '100%' }} overflow="hidden">
            {children}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          mr: 2,
          mb: 2,
        }}
      >
        <SupportForm />
      </Box>
    </Box>
  );
}
