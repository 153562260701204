import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { StyledTextField } from '../../styles/textField';
import { ResponsiveHeading } from '../../styles/heading';
import feedbackImage from '../../assets/images/feedback.jpg';
import MiniOrangeShortLogo from '../../components/MiniOrangeShortLogo';
import { cyanBlueBackgroundButtonStyles } from '../../styles/button';
import { menuItemCSS, StyledSelect } from '../../styles/select';
import { RootState, AppDispatch } from '../../Redux/store';
import { updateForm } from '../../Redux/Slices/Extension-Feedback/feedback-form';
import { submitFeedback } from '../../Redux/Slices/Extension-Feedback/feedbackHandler';

function FeedbackForm() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    email, name, query, description,
  } = useSelector(
    (state: RootState) => state.extensionFeedback,
  );
  const emailRegex = /^(|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  return (
    <Box p={10} display="flex" flexDirection="row">
      <Toaster position="top-center" reverseOrder={false} />
      <Box
        display="flex"
        width="50%"
        flexDirection="column"
        sx={{ gap: { xl: 5, lg: 3 } }}
      >
        <Box display="flex" flexDirection="row" sx={{ gap: { xl: 2, lg: 1 } }}>
          <MiniOrangeShortLogo />
          <ResponsiveHeading>CASB Extension Feedback</ResponsiveHeading>
        </Box>
        <Grid
          container
          sx={{
            rowGap: { xl: 4, lg: 3 },
            border: '1px solid #D9D9D9',
            borderRadius: '10px',
            padding: 4,
          }}
        >
          <Grid item xs={5}>
            <ResponsiveTypography variant="subtitle2">
              Email Address
            </ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <Box>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                name="email"
                value={email}
                placeholder="Enter Email Address"
                onChange={(event: any) => dispatch(
                  updateForm({ target: 'email', value: event.target.value }),
                )}
              />
              <Typography
                display={
                  email === '' || emailRegex.test(email) ? 'none' : 'flex'
                }
                sx={{ fontSize: '10px', color: 'red' }}
              >
                Please provide a valid email address.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography variant="subtitle2">
              Full Name
            </ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              name="name"
              value={name}
              placeholder="Enter Full Name"
              onChange={(event: any) => dispatch(
                updateForm({ target: 'name', value: event.target.value }),
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography variant="subtitle2">
              Query
            </ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <StyledSelect
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              label=""
              style={{ flex: 1 }}
              value={query}
              onChange={(event: any) => dispatch(
                updateForm({ target: 'query', value: event.target.value }),
              )}
            >
              <MenuItem value="Setup Assistance" sx={menuItemCSS}>
                Setup Assistance
              </MenuItem>
              <MenuItem value="Suggestion" sx={menuItemCSS}>
                Suggestion
              </MenuItem>
              <MenuItem value="Report Bug" sx={menuItemCSS}>
                Report Bug
              </MenuItem>
              <MenuItem value="Other" sx={menuItemCSS}>
                Other
              </MenuItem>
            </StyledSelect>
          </Grid>
          <Grid item xs={5}>
            <ResponsiveTypography variant="subtitle2">
              Description
            </ResponsiveTypography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              required
              fullWidth
              multiline
              rows={5}
              maxRows={5}
              onChange={(event: any) => dispatch(
                updateForm({
                  target: 'description',
                  value: event.target.value,
                }),
              )}
              sx={{
                '& .MuiInputBase-input': {
                  fontSize: {
                    xl: '15px !important',
                    lg: '10px !important',
                    md: '10px',
                  },
                  padding: {
                    xl: 0,
                    lg: '4px 7px !important',
                    md: '4px 7px !important',
                  },
                },
              }}
              value={description}
            />
          </Grid>
          <Grid item xs={5}>
            <Button
              sx={{ ...cyanBlueBackgroundButtonStyles, width: '10rem' }}
              onClick={() => dispatch(submitFeedback())}
            >
              Submit Feedback
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        width="50%"
        flexDirection="column"
        sx={{ gap: { xl: 5, lg: 3 } }}
        p={5}
        alignItems="center"
      >
        <Box
          component="img"
          src={feedbackImage}
          sx={{ width: { xl: '33rem', lg: '30rem' } }}
        />
      </Box>
    </Box>
  );
}

export default FeedbackForm;
