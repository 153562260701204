import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  MenuItem,
  Button,
  Tabs,
  Switch,
  Tab,
  Fade,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  updateGroupPolicy,
  getGroupApps,
  getConfiguredPolicies,
  updateRestrictedAppList,
  updateSharePointFileCopy,
  updateSharePointFileDelete,
  updateOneDriveFileCopy,
  updateOneDriveFileDelete,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { buttonIconStyles } from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
  lightBlueBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import { SubTitle } from '../../../../../../styles/subtitle';
import { redirectToEditScreen } from '../../../../../../Redux/Slices/Application/appSlice';
import Cancel from '../../../../../../components/cancelButton';
import ManageRestriction from '../../../../Edit/AppConfiguration/GroupSettings/Common/manageRestriction';

function Office365GroupSettings() {
  const [currentApp, setCurrentApp] = useState<null | string>('onedrive');
  const [showAdvanceSettings, setShowAdvanceSettings] = useState<boolean>(
    false,
  );
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    description,
    apps,
    policy,
    allPolicies,
    defaultPolicyID,
    office365,
  } = useSelector((state: RootState) => state.groupSettings);

  useEffect(() => {
    dispatch(updateRestrictedAppList());
  }, [apps]);

  useEffect(() => {
    if (!policy) {
      dispatch(updateGroupPolicy(defaultPolicyID));
    }
  }, [defaultPolicyID]);

  useEffect(() => {
    dispatch(getGroupApps());
    dispatch(getConfiguredPolicies());
  }, []);

  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        <Grid item xs={1} px={2}>
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
        <Grid item xs={1}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <ResponsiveTypography py={2}>
          Application Permission
        </ResponsiveTypography>
        <Box display="flex" gap="1rem">
          {showAdvanceSettings ? (
            <Button
              sx={lightBlueBackgroundButtonStyles}
              onClick={() => setShowAdvanceSettings(false)}
            >
              Back
            </Button>
          ) : null}
          <Button
            sx={cyanBlueBackgroundButtonStyles}
            onClick={() => setShowAdvanceSettings(true)}
          >
            Advance Settings
          </Button>
        </Box>
      </Box>
      {showAdvanceSettings ? (
        <Box display="flex" flexDirection="column" width="100%" gap="0.5rem">
          <Tabs
            value={currentApp}
            onChange={(event: React.SyntheticEvent, newValue: string) => setCurrentApp(newValue)}
            aria-label="basic tabs example"
          >
            <Tab label="Onedrive" value="onedrive" />
            <Tab label="Sharepoint" value="sharepoint" />
          </Tabs>
          <Fade
            in={currentApp === 'onedrive'}
            {...(currentApp === 'onedrive' ? { timeout: 1000 } : {})}
          >
            <Box
              display={currentApp === 'onedrive' ? 'flex' : 'none'}
              flexDirection="column"
              width="100%"
              gap="1rem"
              pt={{ xl: 2, lg: 1 }}
            >
              <Box
                sx={{
                  height: { xxl: '16rem', xl: '12rem', lg: '10rem' },
                  overflow: 'scroll',
                }}
              >
                {/* New Toggle Switches */}
                <Box display="flex" flexDirection="column" gap="1rem" mt={2}>
                  {/* Toggle Switch for Deleting Files */}
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <SubTitle>Disable File Deletion</SubTitle>
                    <Switch
                      color="primary"
                      checked={office365.onedrive.disableFileDelete}
                      onChange={(event: any) => dispatch(updateOneDriveFileDelete(event.target.checked))}
                    />
                  </Box>

                  {/* Toggle Switch for Making Copies */}
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <SubTitle>Restrict Copying of Files</SubTitle>
                    <Switch
                      color="primary"
                      checked={office365.onedrive.disableFileCopy}
                      onChange={(event: any) => dispatch(updateOneDriveFileCopy(event.target.checked))}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
          <Fade
            in={currentApp === 'sharepoint'}
            {...(currentApp === 'sharepoint' ? { timeout: 1000 } : {})}
          >
            <Box
              display={currentApp === 'sharepoint' ? 'flex' : 'none'}
              flexDirection="column"
              width="100%"
              gap="1rem"
              pt={{ xl: 2, lg: 1 }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box
                  sx={{
                    height: { xxl: '16rem', xl: '12rem', lg: '10rem' },
                    overflow: 'scroll',
                  }}
                >
                  {/* New Toggle Switches */}
                  <Box display="flex" flexDirection="column" gap="1rem" mt={2}>
                    {/* Toggle Switch for Deleting Files */}
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <SubTitle>Disable File Deletion</SubTitle>
                      <Switch
                        color="primary"
                        checked={office365.sharepoint.disableFileDelete}
                        onChange={(event: any) => dispatch(
                          updateSharePointFileDelete(event.target.checked),
                        )}
                      />
                    </Box>

                    {/* Toggle Switch for Making Copies */}
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <SubTitle>Restrict Copying of Files</SubTitle>
                      <Switch
                        color="primary"
                        checked={office365.sharepoint.disableFileCopy}
                        onChange={(event: any) => dispatch(
                          updateSharePointFileCopy(event.target.checked),
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Box>
      ) : (
        <ManageRestriction />
      )}
      <Box display="flex" width="100%" mt={1} gap="1rem">
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          onClick={() => dispatch(redirectToEditScreen(true))}
        >
          Skip
        </Button>
        <Cancel />
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => dispatch(configureNewGroup())}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </Box>
  );
}

export default Office365GroupSettings;
