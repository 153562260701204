import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Autocomplete,
  Chip,
} from '@mui/material';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateFileToggle,
  updateFileBasedrestriction,
  updateEnablePreventDownload,
  updateFileList,
} from '../../Redux/Slices/Policy/policy';
import { StyledTextField } from '../../styles/textField';
import { SmallHeading } from '../../styles/smallHeading';
import { SubLabel } from '../../styles/sublabel';
import { ResponsiveTypography } from '../../styles/inputLabel';

function RestrictImportExport() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    files,
    fileRestrictionToggle,
    enableFileBasedRestriction,
    enablePreventDownload,
  } = useSelector((state: RootState) => state.policySettings);
  const handleTagChange = (event: any, newValue: any) => {
    dispatch(updateFileList(newValue));
  };
  return (
    <Box>
      <SmallHeading>Restrict Import/Export of Files</SmallHeading>
      <Box id="prevent-download" display="flex" p={2} flexDirection="column">
        <Box display="flex" gap="1rem" pb={1}>
          <ResponsiveTypography>Prevent Download</ResponsiveTypography>
        </Box>
        <Box
          pt={1}
          display="flex"
          flexDirection="column"
          borderTop="1px solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enablePreventDownload}
                onChange={() => (enablePreventDownload
                  ? dispatch(updateEnablePreventDownload(false))
                  : dispatch(updateEnablePreventDownload(true)))}
                name="prevent-download-checkbox"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                By enabling this feature, you are restricting access to
                download. Currently this feature is only applicable for Google
                Docs and Google Drive. Downloading and sharing will be
                restricted from the apps or groups on which you apply this
                policy.
              </SubLabel>
            )}
          />
        </Box>
      </Box>
      <Box
        id="file-based-restriction"
        display="flex"
        p={2}
        flexDirection="column"
      >
        <Box display="flex" gap="1rem" pb={1}>
          <ResponsiveTypography>File Based Restriction</ResponsiveTypography>
        </Box>
        <Box
          pt={1}
          display="flex"
          flexDirection="column"
          borderTop="1px solid lightgray"
        >
          <FormControlLabel
            control={(
              <Checkbox
                checked={enableFileBasedRestriction}
                onChange={() => (enableFileBasedRestriction
                  ? dispatch(updateFileBasedrestriction(false))
                  : dispatch(updateFileBasedrestriction(true)))}
                name="file-based-restriction"
                color="primary"
                size="small"
              />
            )}
            label={(
              <SubLabel>
                Enable this feature to control file uploads based on the allowed
                extension list.
              </SubLabel>
            )}
          />
        </Box>
        {enableFileBasedRestriction && (
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              gap="1rem"
              alignItems="center"
            >
              <SubLabel>
                Action if provided files should be allowed or denied:
              </SubLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="FileAddressToggle"
                value={fileRestrictionToggle}
                onChange={(event) => dispatch(updateFileToggle(event?.target.value))}
              >
                <FormControlLabel
                  value="allow"
                  control={<Radio />}
                  label={<SubLabel>Allow</SubLabel>}
                  sx={{ fontSize: '13px' }}
                />
                <FormControlLabel
                  value="deny"
                  control={<Radio />}
                  label={<SubLabel>Deny</SubLabel>}
                  sx={{ fontSize: '15px' }}
                />
              </RadioGroup>
            </Box>
            <Autocomplete
              clearIcon={false}
              options={[]}
              freeSolo
              multiple
              size="small"
              renderTags={(value, props) => value.map((option, index) => (
                <Chip label={option} {...props({ index })} />
              ))}
              value={files || []}
              onChange={handleTagChange}
              renderInput={(params) => <StyledTextField {...params} />}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default RestrictImportExport;
