import React, { useState } from 'react';
import {
  Box, Switch, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCookieDomainRewriteFeature,
} from '../../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../../Redux/store';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import { SubLabel } from '../../../../styles/sublabel';

interface Domain {
  domain: string;
  path: string;
}

function CookieDomainRewrite() {
  const dispatch = useDispatch();
  const cookieDomainRewriteFeature = useSelector((state: RootState) => state.proxySettings.CookieDomainRewriteFeature);

  const initialDomains: Domain[] = cookieDomainRewriteFeature.CookieDomainPathList.map((item) => ({
    domain: item.domain || '',
    path: item.path || '',
  }));

  const [domains, setDomains] = useState<Domain[]>(initialDomains);

  const addFormFields = () => {
    setDomains([...domains, { domain: '', path: '' }]);
  };
  const updateReduxState = (updatedDomains: Domain[]) => {
    dispatch(updateCookieDomainRewriteFeature({
      ...cookieDomainRewriteFeature,
      CookieDomainPathList: updatedDomains,
    }));
  };
  const removeFields = (index: number) => {
    const updatedDomains = [...domains];
    updatedDomains.splice(index, 1);
    setDomains(updatedDomains);
    updateReduxState(updatedDomains);
  };
  const handleChange = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedDomains = [...domains];
    updatedDomains[index] = {
      ...updatedDomains[index],
      [name]: value,
    };
    setDomains(updatedDomains);
    updateReduxState(updatedDomains);
  };

  const handleCookieDomainToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateCookieDomainRewriteFeature({
      ...cookieDomainRewriteFeature,
      enable: event.target.checked,
    }));
  };
  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ResponsiveTypography>Cookie Domain Rewrite</ResponsiveTypography>
          <Switch
            onChange={handleCookieDomainToggle}
            checked={cookieDomainRewriteFeature.enable}
          />
        </Box>
        <SubLabel>
          Cookie Domain Rewrite feature is used to replace a cookie from the
          primary server domain.
        </SubLabel>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFields}>+ Add Cookie Domain</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {domains.map((element: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="domain"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.domain || ''}
                onChange={(event:any) => handleChange(index, event)}
              />
              <StyledTextField
                id="outlined-basic"
                size="small"
                name="path"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.path || ''}
                onChange={(event:any) => handleChange(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFields(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CookieDomainRewrite;
