import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Checkbox,
  Typography,
  Pagination,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArticleIcon from '@mui/icons-material/Article';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { ShimmerTable } from 'react-shimmer-effects';
import { RootState, AppDispatch } from '../../Redux/store';
import MainLayout from '../../components/MainLayout';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../styles/button';
import { menuItemCSS } from '../../styles/select';
import {
  resetPolicyDetails,
  updateOpen,
  updatePolicyState,
} from '../../Redux/Slices/Policy/policy';
import {
  deletePolicy,
  getAllPolicies,
} from '../../Redux/Slices/Policy/policyHandler';
import AddNewPolicy from './AddNewPolicy';
import { SubTitle } from '../../styles/subtitle';
import NoRecordsFound from '../../components/NoRecordsFound';
import { ResponsiveHeading } from '../../styles/heading';
import { buttonIconStyles } from '../../styles/icon';
import { StyledTextField } from '../../styles/textField';
import { handleSearch } from '../../utils/Groups/groupUtility';
import { SubLabel } from '../../styles/sublabel';

const tableCellStyle = {
  borderBottom: '1px solid #D9D9D9',
  paddingTop: 0.8,
  paddingBottom: 0,
  fontSize: { xl: '0.7rem', md: '0.6rem' },
  color: '#6B778C',
};

const tableRowCellStyle = {
  borderBottom: 'none',
  paddingTop: 0.8,
  paddingBottom: 0,
  color: '#172B4D',
  fontSize: { xl: '0.8rem', md: '0.7rem' },
};

function PolicyView() {
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentPolicyId, setCurrentPolicyId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const { allPolicies, status } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const [filteredPolicy, setFilteredPolicy] = useState(allPolicies);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    policyId: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentPolicyId(policyId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentPolicyId(null);
  };

  useEffect(() => {
    dispatch(getAllPolicies());
  }, []);

  useEffect(() => {
    setFilteredPolicy(allPolicies);
  }, [allPolicies]);

  const handleEditPolicy = (id: any) => {
    allPolicies.forEach(async (element: any) => {
      if (element._id === id) {
        await dispatch(updatePolicyState(element));
        await dispatch(updateOpen(true));
      }
    });
    handleMenuClose();
  };

  const configureNewPolicy = async () => {
    await dispatch(resetPolicyDetails());
    await dispatch(updateOpen(true));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    newPage -= 1;
    setPage(newPage);
  };

  const pageCount = Math.ceil(allPolicies.length / rowsPerPage);

  const isEmptyRecords = allPolicies.length === 0;

  return (
    <MainLayout>
      <Box p={4}>
        <AddNewPolicy />
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <ResponsiveHeading>Policy Settings</ResponsiveHeading>
          <SubTitle>Configure Policies for your applications here.</SubTitle>
        </Box>
        <Box mt={5} display="flex" width="100%">
          <Box
            display="flex"
            flexDirection="column"
            p={3}
            border="1px solid #E3E3E3"
            borderRadius="10px"
            width="70%"
          >
            <Box display="flex" justifyContent="space-between">
              <StyledTextField
                id="input-with-icon-textfield"
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={buttonIconStyles} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event: any) => handleSearch(
                  event,
                  allPolicies,
                  filteredPolicy,
                  setFilteredPolicy,
                )}
                sx={{ width: '15rem' }}
              />
              <Button
                sx={{
                  ...cyanBlueBackgroundButtonStyles,
                  width: { xl: '8rem', lg: '6rem' },
                }}
                onClick={configureNewPolicy}
              >
                <AddIcon sx={{ ...buttonIconStyles, color: 'white' }} />
                &nbsp; Add New
              </Button>
            </Box>
            {status === 'idle' && <ShimmerTable row={5} col={4} />}
            {status === 'succeeded' && (
              <TableContainer
                sx={{
                  border: 'none',
                  boxShadow: 'none',
                  overflow: 'auto',
                }}
              >
                <Table sx={{ border: 'none', boxShadow: 'none' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        align="center"
                        sx={{ ...tableCellStyle, align: 'center' }}
                      >
                        <Checkbox
                          size="small"
                          color="primary"
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                      <TableCell sx={tableCellStyle}>Identifiers</TableCell>
                      <TableCell sx={tableCellStyle}>Description</TableCell>
                      <TableCell align="right" sx={tableCellStyle}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isEmptyRecords && (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <NoRecordsFound />
                        </TableCell>
                      </TableRow>
                    )}
                    {!isEmptyRecords
                      && filteredPolicy
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((policy: any) => (
                          <TableRow
                            key={policy._id}
                            sx={{
                              padding: {
                                xl: '16px !important',
                                lg: '10px !important',
                              },
                            }}
                          >
                            <TableCell sx={tableRowCellStyle}>
                              <Checkbox
                                size="small"
                                color="primary"
                                inputProps={{
                                  'aria-label': 'select all desserts',
                                }}
                              />
                            </TableCell>
                            <TableCell sx={tableRowCellStyle}>
                              {policy.identifier}
                            </TableCell>
                            <TableCell sx={tableRowCellStyle}>
                              {policy.description}
                            </TableCell>
                            <TableCell align="right" sx={tableRowCellStyle}>
                              <IconButton
                                onClick={(event) => handleMenuOpen(event, policy._id)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={
                                  Boolean(anchorEl)
                                  && currentPolicyId === policy._id
                                }
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {
                                    boxShadow: 'none',
                                    border: '1px solid #E3E3E3',
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleEditPolicy(policy._id)}
                                  disabled={
                                    currentPolicyId === policy._id
                                    && policy.identifier === 'default'
                                  }
                                  sx={menuItemCSS}
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  disabled={
                                    currentPolicyId === policy._id
                                    && policy.identifier === 'default'
                                  }
                                  onClick={() => dispatch(deletePolicy(policy._id))}
                                  sx={menuItemCSS}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
                {allPolicies.length > 0 && (
                  <Pagination
                    size="small"
                    count={pageCount > 0 ? pageCount : 1}
                    page={page + 1}
                    onChange={handleChangePage}
                    color="primary"
                    sx={{
                      '& .MuiPaginationItem-root': {
                        fontSize: { xl: '15px', lg: '10px' },
                      },
                      paddingTop: 2,
                    }}
                  />
                )}
              </TableContainer>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="30%"
            py={3}
            px={5}
            gap="1rem"
          >
            <Typography variant="button">Guides</Typography>
            <SubLabel pb={2}>
              Our system offers Network-Based, Time-Based, and Usability
              Restrictions. These features control access based on network
              parameters, specific time frames, and user roles and
              functionality.
            </SubLabel>
            <Box display="flex" flexDirection="row" gap="1rem" pb={3}>
              <Button
                startIcon={
                  <YouTubeIcon sx={{ ...buttonIconStyles, color: 'red' }} />
                }
                sx={{
                  ...lightBackgroundButtonStyles,
                  width: {
                    xxl: '12rem !important',
                    xl: '10rem !important',
                    lg: '8rem !important',
                  },
                }}
              >
                Video Guide
              </Button>
              <Button
                startIcon={
                  <ArticleIcon sx={{ ...buttonIconStyles, color: 'blue' }} />
                }
                sx={{
                  ...lightBackgroundButtonStyles,
                  width: {
                    xxl: '12rem !important',
                    xl: '10rem !important',
                    lg: '8rem !important',
                  },
                }}
              >
                Setup Guide
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default PolicyView;
