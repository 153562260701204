import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, Drawer, Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import {
  getAllTenants,
  getUser,
  handleTenantSwitch,
  getCookie,
} from '../../../utils/utility';
import MainLayout from '../../../components/MainLayout';
import { ResponsiveHeading } from '../../../styles/heading';
import { StyledTextField } from '../../../styles/textField';
import { cyanBlueBackgroundButtonStyles } from '../../../styles/button';
import { ResponsiveTypography } from '../../../styles/inputLabel';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface AddTenantModalProps {
  open: boolean;
  setOpen: Function;
  tenants: any;
}

export function AddTenantModal({
  open,
  setOpen,
  tenants,
}: AddTenantModalProps) {
  const [tenantName, setTenantName] = useState('');

  const handleClose = () => {
    setOpen(!open);
  };
  const handleAddTenantForm = (event: any) => {
    setTenantName(event.target.value);
  };
  const handleAddTenant = async () => {
    const token = await getCookie('token');
    let tenantNameUnique: boolean = true;
    tenants?.forEach((tenant: any) => {
      if (tenant.tenant_name === tenantName) {
        tenantNameUnique = false;
      }
    });
    if (!tenantNameUnique) {
      toast.error('Tenant Name Already Exists!');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-tenant`,
          {
            tenant_name: tenantName,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          setOpen(!open);
          await handleTenantSwitch({ tenantName });
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_ADD_TENANT_ERROR);
        });
    }
  };
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box px={5} py={3} width="25vw">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          borderBottom="1px solid #EBEBEB"
        >
          <ResponsiveHeading>Configure Tenant</ResponsiveHeading>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ overflowY: 'hidden', gap: '2rem' }}
          pt={4}
        >
          <Box display="flex" flexDirection="column" gap="0.5rem">
            <ResponsiveTypography>Tenant Identifier</ResponsiveTypography>
            <StyledTextField
              id="tenant-name"
              value={tenantName}
              onChange={handleAddTenantForm}
            />
          </Box>
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={handleAddTenant}
          >
            Add Tenant
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

function AddTenantView() {
  const [tenants, setTenants] = useState<any>();
  const [reloadTenants, setReloadTenants] = useState(false);
  const [openAddTenantModal, setOpenAddTenantModal] = useState(false);
  const [activeTenant, setActiveTenant] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const totalPages = Math.ceil((tenants?.length || 0) / rowsPerPage); //
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const identifierValue = urlParams.has('open') ? urlParams.get('open') : '';
  useEffect(() => {
    if (identifierValue === 'true') {
      setOpenAddTenantModal(true);
    }
  }, []);

  useEffect(() => {
    async function fetchAllTenants() {
      try {
        const response = await getAllTenants();
        setTenants(response);
      } catch (error) {
        console.error('Error fetching tenants', error);
      }
    }
    fetchAllTenants();
  }, [reloadTenants]);

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const paginatedData = tenants?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  useEffect(() => {
    async function getCurrentActiveTenant() {
      const user = await getUser();
      tenants?.forEach((tenant: any) => {
        if (tenant?._id === user?.current_active_tenant_id) {
          setActiveTenant(tenant.tenant_name);
        }
      });
    }
    getCurrentActiveTenant();
  }, [tenants, reloadTenants]);

  const handleAddTenantModal = () => {
    setOpenAddTenantModal(true);
  };

  const userSwitchTenant = async (selectedTenant: string) => {
    await handleTenantSwitch({ tenantName: selectedTenant });
  };

  const deleteTenant = async (selectedTenant: string) => {
    setReloadTenants(!reloadTenants);
    const token = await getCookie('token');
    const isActiveTenant = activeTenant === selectedTenant;
    if (isActiveTenant) {
      toast.error('Cannot Delete Current Active Tenant');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-tenant`,
          {
            tenant_name: selectedTenant,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(async () => {
          setReloadTenants(!reloadTenants);
          toast.success('Deleted Tenant');
        });
    }
  };

  return (
    <MainLayout>
      <Box display="flex" overflow="hidden" flexDirection="column">
        <ToastContainer />
        <AddTenantModal
          open={openAddTenantModal}
          setOpen={setOpenAddTenantModal}
          tenants={tenants}
        />
        <Box display="flex" px="2rem" py="1rem" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Tenant Settings</Typography>
          </Box>
          <Box>
            <Button
              sx={{
                backgroundColor: '#2D3748',
                color: '#ffffff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#2D3748',
                  color: '#ffffff',
                },
              }}
              onClick={handleAddTenantModal}
            >
              <AddIcon />
              Add New Tenant
            </Button>
          </Box>
        </Box>
        <Box>
          <TableContainer
            component={Paper}
            sx={{ padding: '2rem', height: '75vh', overflow: 'auto' }}
          >
            <Table sx={{ width: '95%' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Tenant Name</StyledTableCell>
                  <StyledTableCell align="center">URL</StyledTableCell>
                  <StyledTableCell align="center">Enable</StyledTableCell>
                  <StyledTableCell align="left"> Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData?.map((tenant: any) => (
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {tenant?.tenant_name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {tenant?.url}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Switch
                        checked={tenant.tenant_name === activeTenant}
                        onClick={() => userSwitchTenant(tenant.tenant_name)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {tenant.tenant_name === 'default' ? (
                        <Button>
                          <DeleteIcon sx={{ color: 'grey' }} />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => deleteTenant(tenant.tenant_name)}
                        >
                          <DeleteIcon sx={{ color: 'red' }} />
                        </Button>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {!tenants && (
                  <StyledTableRow>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center">
                      No Configurations
                    </StyledTableCell>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
                marginRight: '5rem',
              }}
            >
              <Button onClick={handlePrevPage} disabled={page === 0}>
                Previous
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={page === totalPages - 1}
              >
                Next
              </Button>
            </div>
          </TableContainer>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AddTenantView;
