import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid, Box, Card, CardContent, MenuItem,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AppDispatch, RootState } from '../../../../../../Redux/store';
import { SmallHeading } from '../../../../../../styles/smallHeading';
import {
  removeApp,
  updateAppAllowedList,
  updateAppBlockedList,
  updateAppRestrictionList,
  updateCustomRestrictionList,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import { smallIconStyles } from '../../../../../../styles/icon';
import { StyledSelect } from '../../../../../../styles/select';
import {
  determineAppPolicyID,
  handleAppCustomRestrictionPolicyChange,
} from '../../../../../../utils/Groups/groupUtility';
import { SubLabel } from '../../../../../../styles/sublabel';

function ManageRestriction() {
  const dispatch = useDispatch<AppDispatch>();
  const { isConfigured } = useSelector((state: RootState) => state.application);
  const {
    apps,
    restrictedApps,
    allowedApps,
    blockApps,
    defaultPolicyID,
    customRestrictionApps,
    allPolicies,
  } = useSelector((state: RootState) => state.groupSettings);
  console.log('restrictedApps', restrictedApps);
  const handleDragEnd = async (result: any) => {
    const { destination, draggableId } = result;
    if (!destination) {
      return;
    }

    const appKey = draggableId;
    const status = destination.droppableId;
    await dispatch(removeApp(appKey));
    switch (status) {
      case 'App Restriction':
        dispatch(updateAppRestrictionList(appKey));
        break;
      case 'No App Restrictions':
        dispatch(updateAppAllowedList(appKey));
        break;
      case 'Disable Apps':
        dispatch(updateAppBlockedList(appKey));
        break;
      case 'Custom Restrictions':
        dispatch(updateCustomRestrictionList(appKey));
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid
          container
          spacing={2}
          mt={{ xl: '1 !important', lg: '0.5 !important' }}
        >
          {[
            'App Restriction',
            'No App Restrictions',
            'Disable Apps',
            'Custom Restrictions',
          ].map((status) => (
            <Grid
              item
              xs={3}
              px={{ xl: '3 !important', lg: '2 !important' }}
              py={1}
            >
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="center">
                    <SmallHeading gutterBottom color="#0065FF">
                      {status}
                    </SmallHeading>
                  </Box>
                  <Droppable droppableId={status}>
                    {(provided: any) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{
                          height: {
                            xxl: isConfigured ? '28rem' : '17rem ',
                            xl: isConfigured ? '24.5rem' : '13rem ',
                            lg: isConfigured ? '20rem' : '9rem ',
                          },
                          overflow: 'scroll',
                        }}
                      >
                        {apps
                          ?.filter((app) => {
                            if (status === 'App Restriction') {
                              return restrictedApps.includes(app.appKey);
                            }
                            if (status === 'No App Restrictions') {
                              return allowedApps.includes(app.appKey);
                            }
                            if (status === 'Disable Apps') {
                              return blockApps.includes(app.appKey);
                            }
                            if (status === 'Custom Restrictions') {
                              return (
                                customRestrictionApps
                                && app.appKey in customRestrictionApps
                              );
                            }
                            return false;
                          })
                          .map((app, index) => (
                            <Draggable
                              key={app.appKey}
                              draggableId={app.appKey}
                              index={index}
                            >
                              {(providedx: any) => (
                                <Box
                                  ref={providedx.innerRef}
                                  {...providedx.draggableProps}
                                  {...providedx.dragHandleProps}
                                  sx={{
                                    cursor: 'move',
                                    py: 0,
                                    px: 2,
                                    mb: 1,
                                  }}
                                >
                                  {status === 'Custom Restrictions' ? (
                                    <Box
                                      sx={{
                                        width: '100% !important',
                                        backgroundColor: '#F5FBFF',
                                        border: {
                                          xl: '2px solid #9fbae3 !important',
                                          lg: '1px solid #9fbae3 !important',
                                        },
                                        paddingY: '0.25rem',
                                        paddingX: '0.5rem',
                                        borderRadius: '17px',
                                        height: {
                                          xl: '2.65rem !important',
                                          lg: '2.2rem !important',
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: '100% !important',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent:
                                            'flex-start !important',
                                        }}
                                      >
                                        <DragIndicatorIcon
                                          sx={{
                                            ...smallIconStyles,
                                            color: '#5E5E5E',
                                            mr: 1,
                                            borderRight: '1px solid #5E5E5E',
                                          }}
                                        />
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          width="100%"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Box
                                            component="img"
                                            src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                                            alt={app.name}
                                            sx={{ height: '15px', mr: 1 }}
                                          />
                                          <Box>
                                            <StyledSelect
                                              value={determineAppPolicyID(
                                                app.appKey,
                                                customRestrictionApps,
                                                defaultPolicyID,
                                              )}
                                              onChange={(
                                                event: React.ChangeEvent<HTMLSelectElement>,
                                              ) => handleAppCustomRestrictionPolicyChange(
                                                app.appKey,
                                                event.target.value,
                                                dispatch,
                                              )}
                                              sx={{
                                                borderRadius: '17px',
                                                width: {
                                                  xl: '8rem',
                                                  lg: '6rem',
                                                },
                                              }}
                                            >
                                              {allPolicies.map(
                                                (source: any) => (
                                                  <MenuItem
                                                    key={source.identifier}
                                                    value={source._id}
                                                  >
                                                    {source.identifier}
                                                  </MenuItem>
                                                ),
                                              )}
                                            </StyledSelect>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        width: '100% !important',
                                        backgroundColor: '#F5FBFF',
                                        border: '2px solid #9fbae3',
                                        paddingY: '0.25rem',
                                        paddingX: '0.5rem',
                                        borderRadius: '17px',
                                        height: {
                                          xl: '2.2rem !important',
                                          lg: '2.0rem !important',
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: '100% !important',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent:
                                            'flex-start !important',
                                        }}
                                      >
                                        <DragIndicatorIcon
                                          sx={{
                                            ...smallIconStyles,
                                            color: '#5E5E5E',
                                            mr: 1,
                                            borderRight: '1px solid #5E5E5E',
                                          }}
                                        />
                                        <Box
                                          component="img"
                                          src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                                          alt={app.name}
                                          sx={{ width: '15px', mr: 1 }}
                                        />
                                        <SubLabel>{app.name}</SubLabel>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
    </div>
  );
}

export default ManageRestriction;
