import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer, Box, Grid, Button,
} from '@mui/material';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ResponsiveTypography } from '../../styles/inputLabel';
import curveimg from '../../assets/images/Policy-curve.svg';
import { StyledTextField } from '../../styles/textField';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateIdentifier,
  updateDescription,
  updateOpen,
} from '../../Redux/Slices/Policy/policy';
import { configureNewPolicySettings } from '../../Redux/Slices/Policy/policyHandler';
import {
  lightBackgroundButtonStyles,
  cyanBlueBackgroundButtonStyles,
} from '../../styles/button';
import NetworkSettings from '../../components/Policy/networkRestriction';
import DrawerHeading from '../../components/Drawer/drawerHeading';
import TimeBasedRestriction from '../../components/Policy/timeBasedRestriction';
import RestrictImportExport from '../../components/Policy/restrictImportExport';
import ClipboardRestriction from '../../components/Policy/clipboardRestriction';

export default function AddNewPolicy() {
  const dispatch = useDispatch<AppDispatch>();
  const { identifier, description, open } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const networkSettingsRef = useRef<HTMLDivElement>(null);
  const timeRestrictionRef = useRef<HTMLDivElement>(null);
  const importExportRestrictionRef = useRef<HTMLDivElement>(null);
  const updatePolicy = () => {
    dispatch(configureNewPolicySettings());
  };

  return (
    <Drawer
      open={open}
      onClose={() => dispatch(updateOpen(false))}
      anchor="right"
    >
      <Box
        sx={{
          width: {
            xxl: '60rem !important',
            xl: '55rem !important',
            lg: '45rem !important',
            md: '40rem !important',
          },
        }}
        p={2}
      >
        <DrawerHeading
          title="Policy Settings"
          subtitle="Configure Policy for your application here."
        />
        <Box pt={2}>
          <Grid container pb={1} rowGap={1}>
            <Grid item xs={2}>
              <ResponsiveTypography>Policy Name</ResponsiveTypography>
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name="identifier"
                value={identifier || ''}
                onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
              />
            </Grid>
          </Grid>
          <Grid container pb={2}>
            <Grid item xs={2}>
              <ResponsiveTypography>Description</ResponsiveTypography>
            </Grid>
            <Grid item xs={5}>
              <StyledTextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name="description"
                value={description || ''}
                onChange={(event: any) => dispatch(updateDescription(event.target.value))}
              />
            </Grid>
          </Grid>
          <Box display="flex" width="100%" pt={2} borderTop="1px solid #E3E3E3">
            <Box width="70%">
              <Box
                id="restriction-List"
                sx={{
                  height: {
                    xxl: '67vh !important',
                    xl: '65vh !important',
                    lg: '63vh !important',
                    md: '60vh !important',
                  },
                  overflow: 'scroll',
                }}
                display="flex"
                flexDirection="column"
                gap="0.5rem"
              >
                <Box ref={networkSettingsRef}>
                  <NetworkSettings />
                </Box>
                <Box ref={timeRestrictionRef}>
                  <TimeBasedRestriction />
                </Box>
                <Box ref={importExportRestrictionRef}>
                  <RestrictImportExport />
                </Box>
                <Box
                  id="disable-clipboard"
                  display="flex"
                  p={2}
                  flexDirection="column"
                >
                  <ClipboardRestriction />
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" width="40%" pl={2}>
              <Box
                sx={{
                  backgroundImage: 'linear-gradient(#FFFFFF, #0065FF, #FFFFFF)',
                  width: '3px !important',
                }}
              />
              <Box ml={1}>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                    onClick={() => networkSettingsRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })}
                  >
                    <PublicOutlinedIcon sx={{ width: { xl: 20, lg: 15 } }} />
                    &nbsp;&nbsp;
                    <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                      Network Based Restriction
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl={1.5}>
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => networkSettingsRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          IP Restriction
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => networkSettingsRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Location Based Restriction
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                    onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })}
                  >
                    <AccessTimeFilledOutlinedIcon
                      sx={{ width: { xl: 20, lg: 15 } }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                      Time Based Restriction
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl={1.5}>
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Time of Access Configuration
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          User Session Configuration
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                    onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })}
                  >
                    <DescriptionOutlinedIcon
                      sx={{ width: { xl: 20, lg: 15 } }}
                    />
                    &nbsp;&nbsp;
                    <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                      Restrict Import/Export of Files
                    </ResponsiveTypography>
                  </Box>
                  <Box display="flex" flexDirection="row" pl={1.5}>
                    <Box>
                      <img src={curveimg} alt="curve" height={55} />
                    </Box>
                    <Box ml={1}>
                      <Box
                        mt={1}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Prevent Download
                        </ResponsiveTypography>
                      </Box>
                      <Box
                        m={0}
                        display="flex"
                        flexDirection="row"
                        gap="1rem"
                        onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                          behavior: 'smooth',
                        })}
                      >
                        <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                          Restrict Import of Files
                        </ResponsiveTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                    onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })}
                  >
                    <ContentPasteIcon sx={{ width: { xl: 20, lg: 15 } }} />
                    &nbsp;&nbsp;
                    <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                      Clipboard Restriction
                    </ResponsiveTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="1rem">
          <Button
            variant="text"
            sx={{ ...lightBackgroundButtonStyles, width: { xl: '5rem' } }}
            onClick={() => dispatch(updateOpen(false))}
          >
            Cancel
          </Button>
          <Button
            variant="text"
            sx={cyanBlueBackgroundButtonStyles}
            onClick={updatePolicy}
          >
            Save & Next
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
