import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ResponsiveHeading } from '../../styles/heading';
import { mediumIconStyles } from '../../styles/icon';
import { ResponsiveSubHeading } from '../../styles/subHeading';

interface DrawerHeadingProps {
  title: string;
  subtitle: string;
}

export default function DrawerHeading({ title, subtitle }: DrawerHeadingProps) {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      py={2}
      borderBottom={`1px solid ${theme.palette.border.main}`}
    >
      <Box>
        <ResponsiveHeading>{title}</ResponsiveHeading>
        <ResponsiveSubHeading>{subtitle}</ResponsiveSubHeading>
      </Box>
      <CloseIcon
        sx={{ ...mediumIconStyles, color: theme.palette.border.main }}
      />
    </Box>
  );
}
