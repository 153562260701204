import React from 'react';
import { Button } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from 'react-router-dom';
import { lightBackgroundButtonStyles } from '../styles/button';
import { buttonIconStyles } from '../styles/icon';

export default function Cancel() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard', { replace: true });
  };

  return (
    <Button
      variant="text"
      sx={{ ...lightBackgroundButtonStyles, width: '6rem' }}
      startIcon={(
        <DeleteOutlineOutlinedIcon
          sx={{ ...buttonIconStyles, color: '#373737' }}
        />
      )}
      onClick={handleClick}
    >
      Cancel
    </Button>
  );
}
