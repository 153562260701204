import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useDispatch } from 'react-redux';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../styles/button';
import { buttonIconStyles } from '../styles/icon';
import { ResponsiveTypography } from '../styles/inputLabel';
import { deleteAuthenticationSource } from '../Redux/Slices/Application/AuthenticationSource/authenticationSourceHandler';
import { AppDispatch } from '../Redux/store';

function ConfirmDeleteDialog({
  open,
  setOpen,
  action,
}: {
  open: boolean;
  setOpen: Function;
  action: any;
}) {
  const dispatch = useDispatch<AppDispatch>();

  const handleConfirmDelete = () => {
    setOpen(false);
    dispatch(deleteAuthenticationSource(action));
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ borderRadius: '15px', paddingX: '20px' }}
    >
      <DialogTitle id="alert-dialog-title">
        <ResponsiveTypography>Confirm Delete</ResponsiveTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ResponsiveTypography>
            Are you sure you want to delete this?
          </ResponsiveTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '6rem' }}
          onClick={() => setOpen(false)}
          startIcon={(
            <DeleteOutlineOutlinedIcon
              sx={{ ...buttonIconStyles, color: '#373737' }}
            />
          )}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmDelete}
          sx={{ ...cyanBlueBackgroundButtonStyles, width: '5rem' }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteDialog;
