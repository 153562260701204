import React, { useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../Redux/store';
import AuthenticationSource from './AuthenticationSource/authenticationSource';
import { ResponsiveImage } from '../../../styles/app-logo';
import AuthenticationSourceInformation from './AuthenticationSource/info&links';
import ManagePolicy from './Policy/Policy';
import { apps } from '../app';
import { changeScreen } from '../../../Redux/Slices/Application/appSlice';
import steps from './steps';
import { ResponsiveHeading } from '../../../styles/heading';
import { ResponsiveTypography } from '../../../styles/inputLabel';

interface IProps {
  app: string;
}

function ConfigurationScreen({ app }: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { step } = useSelector((state: RootState) => state.application);
  useEffect(() => {
    dispatch(changeScreen(process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN ?? ''));
  }, [apps]);
  return (
    <Box p={3}>
      <Box width="100%" display="flex" flexDirection="row" gap="1rem">
        <Box
          display="flex"
          flexDirection="row"
          gap="1rem"
          pr={3}
          borderRight="0.75px solid #A2A2A2"
          alignItems="center"
        >
          <ResponsiveImage src={apps[app].smallLogo} alt="application-logo" />
          <ResponsiveHeading>{apps[app].title}</ResponsiveHeading>
        </Box>
        <Box display="flex" flexDirection="column" gap="0.25rem" pl={2}>
          <ResponsiveHeading>
            {steps[step as keyof typeof steps].title}
          </ResponsiveHeading>
          <ResponsiveTypography>
            {steps[step as keyof typeof steps].subtitle}
          </ResponsiveTypography>
        </Box>
      </Box>
      <Box pt={4} display="flex" width="100%">
        <Box
          border="1px solid #EAEAEA"
          borderRadius="10px"
          sx={{
            padding: {
              xxl: 3,
              xl: 3,
              lg: 2,
              md: 2,
            },
            transition: 'width 0.3s',
          }}
          width="100%"
        >
          <Tabs
            value={step}
            onChange={(event: React.SyntheticEvent, newValue: string) => dispatch(changeScreen(newValue))}
            sx={{ borderBottom: '1px solid #EDEDED' }}
          >
            <Tab
              label={(
                <ResponsiveTypography>
                  Authentication Source
                </ResponsiveTypography>
              )}
              value={process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN}
            />
            <Tab
              label={
                <ResponsiveTypography>Basic Settings</ResponsiveTypography>
              }
              value={process.env.REACT_APP_BASIC_SETTINGS_SCREEN}
            />
            <Tab
              label={<ResponsiveTypography>Manage Policy</ResponsiveTypography>}
              value={process.env.REACT_APP_POLICY_SETTINGS_SCREEN}
            />
            <Tab
              label={<ResponsiveTypography>Manage Groups</ResponsiveTypography>}
              value={process.env.REACT_APP_GROUP_SETTINGS_SCREEN}
            />
          </Tabs>
          <Box display="flex" flexDirection="column" width="100%" pt={3}>
            {step === process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN && (
              <AuthenticationSource />
            )}
            {step === process.env.REACT_APP_BASIC_SETTINGS_SCREEN
              && apps[app].configurationScreen?.basicSettingScreen}
            {step === process.env.REACT_APP_POLICY_SETTINGS_SCREEN && (
              <ManagePolicy />
            )}
            {step === process.env.REACT_APP_GROUP_SETTINGS_SCREEN
              && apps[app].configurationScreen?.groupSettingScreen}
          </Box>
        </Box>
        <Box
          px={{ xl: 8, lg: 8, md: 6 }}
          width="40%"
          display={
            step === process.env.REACT_APP_GROUP_SETTINGS_SCREEN
            || step === process.env.REACT_APP_POLICY_SETTINGS_SCREEN
              ? 'none'
              : 'flex'
          }
        >
          {step === process.env.REACT_APP_AUTHENTICATION_SOURCE_SCREEN && (
            <AuthenticationSourceInformation />
          )}
          {step === process.env.REACT_APP_BASIC_SETTINGS_SCREEN
            && apps[app].configurationScreen?.basicSettingsInfo}
        </Box>
      </Box>
    </Box>
  );
}
export default ConfigurationScreen;
