import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid } from '@mui/material';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../styles/button';
import { RootState, AppDispatch } from '../../../../Redux/store';
import {
  nextScreen,
  configureNewPolicy,
} from '../../../../Redux/Slices/Application/appSlice';
import { ResponsiveTypography } from '../../../../styles/inputLabel';
import { StyledTextField } from '../../../../styles/textField';
import curveimg from '../../../../assets/images/Policy-curve.svg';
import {
  updateIdentifier,
  updateDescription,
} from '../../../../Redux/Slices/Policy/policy';
import NetworkSettings from '../../../../components/Policy/networkRestriction';
import TimeBasedRestriction from '../../../../components/Policy/timeBasedRestriction';
import RestrictImportExport from '../../../../components/Policy/restrictImportExport';
import ClipboardRestriction from '../../../../components/Policy/clipboardRestriction';
import { smallIconStyles } from '../../../../styles/icon';

function ManagePolicy() {
  const dispatch = useDispatch<AppDispatch>();
  const { identifier, description } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const networkSettingsRef = useRef<HTMLDivElement>(null);
  const timeRestrictionRef = useRef<HTMLDivElement>(null);
  const importExportRestrictionRef = useRef<HTMLDivElement>(null);
  const updatePolicy = () => {
    dispatch(configureNewPolicy());
  };
  return (
    <>
      <Box>
        <Grid container pb={1}>
          <Grid item xs={2}>
            <ResponsiveTypography>Policy Name</ResponsiveTypography>
          </Grid>
          <Grid item xs={5}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '15px',
                },
              }}
              name="identifier"
              value={identifier || ''}
              onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <ResponsiveTypography>Description</ResponsiveTypography>
          </Grid>
          <Grid item xs={5}>
            <StyledTextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              inputProps={{
                style: {
                  fontSize: '15px',
                },
              }}
              name="description"
              value={description || ''}
              onChange={(event: any) => dispatch(updateDescription(event.target.value))}
            />
          </Grid>
        </Grid>
        <Box display="flex" width="100%" pt={3}>
          <Box width="70%">
            <Box
              id="restriction-List"
              sx={{
                height: { xl: '43vh !important', lg: '40vh !important' },
                overflow: 'scroll',
              }}
              display="flex"
              flexDirection="column"
              gap={{
                xl: '0.5rem !important',
                lg: '0.25rem !important',
                md: '0.2rem !important',
              }}
            >
              <Box ref={networkSettingsRef}>
                <NetworkSettings />
              </Box>

              <Box ref={timeRestrictionRef}>
                <TimeBasedRestriction />
              </Box>
              <Box ref={importExportRestrictionRef}>
                <RestrictImportExport />
              </Box>
              <Box
                id="disable-copy"
                display="flex"
                p={2}
                flexDirection="column"
              >
                <ClipboardRestriction />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" width="30%" pl={2}>
            <Box
              sx={{
                backgroundImage: 'linear-gradient(#FFFFFF, #0065FF, #FFFFFF)',
                width: '3px !important',
              }}
            />
            <Box ml={1}>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                  onClick={() => networkSettingsRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  })}
                >
                  <PublicOutlinedIcon sx={smallIconStyles} />
                  &nbsp;&nbsp;
                  <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                    Network Based Restriction
                  </ResponsiveTypography>
                </Box>
                <Box display="flex" flexDirection="row" pl={1.5}>
                  <Box>
                    <Box
                      component="img"
                      src={curveimg}
                      alt="curve"
                      height={{ xl: '3rem', lg: '2.5rem', md: '2rem' }}
                    />
                  </Box>
                  <Box ml={1}>
                    <Box
                      mt={1}
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      onClick={() => networkSettingsRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      })}
                    >
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        IP Restriction
                      </ResponsiveTypography>
                    </Box>
                    <Box
                      m={0}
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      onClick={() => networkSettingsRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      })}
                    >
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        Location Based Restriction
                      </ResponsiveTypography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                  onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  })}
                >
                  <AccessTimeFilledOutlinedIcon sx={smallIconStyles} />
                  &nbsp;&nbsp;
                  <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                    Time Based Restriction
                  </ResponsiveTypography>
                </Box>
                <Box display="flex" flexDirection="row" pl={1.5}>
                  <Box>
                    <Box
                      component="img"
                      src={curveimg}
                      alt="curve"
                      height={{ xl: '3rem', lg: '2.5rem', md: '2rem' }}
                    />
                  </Box>
                  <Box ml={1}>
                    <Box
                      mt={1}
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      onClick={() => timeRestrictionRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      })}
                    >
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        Time of Access Configuration
                      </ResponsiveTypography>
                    </Box>
                    <Box m={0} display="flex" flexDirection="row" gap="1rem">
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        User Session Configuration
                      </ResponsiveTypography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                  onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                  })}
                >
                  <DescriptionOutlinedIcon sx={smallIconStyles} />
                  &nbsp;&nbsp;
                  <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                    Restrict Import/Export of Files
                  </ResponsiveTypography>
                </Box>
                <Box display="flex" flexDirection="row" pl={1.5}>
                  <Box>
                    <Box
                      component="img"
                      src={curveimg}
                      alt="curve"
                      height={{ xl: '3rem', lg: '2.5rem', md: '2rem' }}
                    />
                  </Box>
                  <Box ml={1}>
                    <Box
                      mt={1}
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      })}
                    >
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        Prevent Download
                      </ResponsiveTypography>
                    </Box>
                    <Box
                      m={0}
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                      })}
                    >
                      <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                        Restrict Import of Files
                      </ResponsiveTypography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ paddingY: { xl: 1, lg: 0.5 } }}
                    onClick={() => importExportRestrictionRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                    })}
                  >
                    <ContentPasteIcon sx={smallIconStyles} />
                    &nbsp;&nbsp;
                    <ResponsiveTypography sx={{ cursor: 'pointer' }}>
                      Clipboard Restriction
                    </ResponsiveTypography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap="1rem">
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: { xl: '5rem' } }}
          onClick={() => dispatch(nextScreen())}
        >
          Skip
        </Button>
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={updatePolicy}
        >
          Save & Next
        </Button>
      </Box>
    </>
  );
}

export default ManagePolicy;
