import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Grid, MenuItem, Button,
} from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { RootState, AppDispatch } from '../../../../../../Redux/store';
import { StyledTextField } from '../../../../../../styles/textField';
import { ResponsiveTypography } from '../../../../../../styles/inputLabel';
import { StyledSelect } from '../../../../../../styles/select';
import {
  updateIdentifier,
  updateDescription,
  getGroupApps,
  getConfiguredPolicies,
  updateGroupPolicy,
  updateClose,
} from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/group';
import {
  getUserSession,
  revokeUserSession,
} from '../../../../../../Redux/Slices/Application/SessionManagement/sessionHandler';
import { buttonIconStyles } from '../../../../../../styles/icon';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../../../../styles/button';
import { configureNewGroup } from '../../../../../../Redux/Slices/Application/AppConfiguration/Group/groupHandler';
import ManageRestriction from './manageRestriction';

function EditGroupSettings() {
  const dispatch = useDispatch<AppDispatch>();
  const {
    identifier,
    description,
    apps,
    policy,
    allPolicies,
    defaultPolicyID,
  } = useSelector((state: RootState) => state.groupSettings);
  const { session } = useSelector((state: RootState) => state.session);
  const [, setFilteredData] = useState(apps);
  const [, setAppImages] = useState<{ [key: string]: string }>({});

  const revokeGroupUserSession = (userSessions: [], groupIdentifier: string) => {
    const filteredGroupSession = userSessions.filter((group:any) => group.identifier === groupIdentifier);
    const selectedUsers = [...filteredGroupSession].reduce(
      (acc: string[], usersession: any) => [
        ...acc,
        ...(usersession.users || []).map((user: any) => user.uuid_key),
      ],
      [],
    );
    dispatch(revokeUserSession(selectedUsers));
  };

  useEffect(() => {
    const appKeyToImageSrc = apps.reduce((acc, app) => {
      acc[app.appKey] = app.imageSrc;
      return acc;
    }, {});
    setAppImages(appKeyToImageSrc);
  }, [apps]);
  useEffect(() => {
    dispatch(getGroupApps());
    dispatch(getConfiguredPolicies());
    dispatch(getUserSession());
  }, []);
  useEffect(() => {
    setFilteredData(apps);
    if (!policy) {
      dispatch(updateGroupPolicy(defaultPolicyID));
    }
  }, [apps]);
  return (
    <Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}>
          <ResponsiveTypography>Name</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="identifier"
            value={identifier || ''}
            onChange={(event: any) => dispatch(updateIdentifier(event.target.value))}
          />
        </Grid>
        <Grid item xs={1} px={2}>
          <ResponsiveTypography>Description</ResponsiveTypography>
        </Grid>
        <Grid item xs={6}>
          <StyledTextField
            id="outlined-basic"
            size="small"
            variant="outlined"
            inputProps={{
              style: {
                fontSize: '15px',
              },
            }}
            name="description"
            value={description || ''}
            onChange={(event: any) => dispatch(updateDescription(event.target.value))}
          />
        </Grid>
        <Grid item xs={1}>
          <ResponsiveTypography>Policy</ResponsiveTypography>
        </Grid>
        <Grid item xs={4}>
          <StyledSelect
            value={policy || defaultPolicyID}
            onChange={(event: any) => dispatch(updateGroupPolicy(event.target.value))}
          >
            {allPolicies.map((source: any) => (
              <MenuItem key={source.identifier} value={source._id}>
                {source.identifier}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <Box>
        <ResponsiveTypography py={1}>
          Application Permission
        </ResponsiveTypography>
        <ManageRestriction />
      </Box>
      <Box display="flex" width="100%" mt={1} gap="1rem">
        <Button
          variant="text"
          sx={lightBackgroundButtonStyles}
          onClick={() => dispatch(updateClose(false))}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          sx={cyanBlueBackgroundButtonStyles}
          onClick={() => {
            dispatch(configureNewGroup());
            revokeGroupUserSession(session, identifier);
          }}
        >
          Save and Next&nbsp;&nbsp;
          <ArrowForwardOutlinedIcon
            sx={{ ...buttonIconStyles, color: 'white' }}
          />
        </Button>
      </Box>
    </Box>
  );
}

export default EditGroupSettings;
