import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
} from '@mui/material';
import { RootState, AppDispatch } from '../../Redux/store';
import {
  updateEnableDisableClipboard,
  updateClipboardRestrictions,
} from '../../Redux/Slices/Policy/policy';
import { SubLabel } from '../../styles/sublabel';
import { StyledTextField } from '../../styles/textField';
import { ResponsiveTypography } from '../../styles/inputLabel';

function ClipboardRestriction() {
  const dispatch = useDispatch<AppDispatch>();
  const { enableClipboardRestriction, clipboardRestrictions } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const clipboardString = [
    'Copy',
    'Cut',
    'Paste',
    'Text Selection',
    'Context Menu',
  ];
  const restrictionMappings: { [key: string]: string } = {
    'Text Selection': 'text_selection',
    'Context Menu': 'context_menu',
    Copy: 'copy',
    Cut: 'cut',
    Paste: 'paste',
  };
  const inverseRestrictionMappings: { [key: string]: string } = {
    text_selection: 'Text Selection',
    context_menu: 'Context Menu',
    copy: 'Copy',
    cut: 'Cut',
    paste: 'Paste',
  };
  return (
    <Box>
      <Box display="flex" gap="1rem" pb={1}>
        <ResponsiveTypography>Disable Clipboard</ResponsiveTypography>
      </Box>
      <Box
        pt={1}
        display="flex"
        flexDirection="column"
        borderTop="1px solid lightgray"
      >
        <FormControlLabel
          control={(
            <Checkbox
              size="small"
              checked={enableClipboardRestriction}
              onChange={() => (enableClipboardRestriction
                ? dispatch(updateEnableDisableClipboard(false))
                : dispatch(updateEnableDisableClipboard(true)))}
              name="disable-clipboard"
              color="primary"
            />
          )}
          label={(
            <SubLabel>
              By enabling this feature, you are restricting users from
              performing selected operations on the contents of the page.
            </SubLabel>
          )}
        />
      </Box>
      {enableClipboardRestriction && (
        <Box mt={1}>
          <Autocomplete
            disablePortal
            fullWidth
            id="timezone"
            size="small"
            multiple
            options={clipboardString}
            renderInput={(params) => <StyledTextField {...params} />}
            renderTags={(value, props) => value.map((option, index) => (
              <Chip label={option} {...props({ index })} />
            ))}
            value={clipboardRestrictions.map(
              (value: string) => inverseRestrictionMappings[value],
            )}
            onChange={(event: any, values: any) => {
              const updatedRestrictions = values.map(
                (value: string) => restrictionMappings[value] || value,
              );
              dispatch(updateClipboardRestrictions(updatedRestrictions));
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ClipboardRestriction;
