import React from 'react';
import { Box } from '@mui/material';
import Logo from '../assets/images/miniorange-rp.png';

export default function MiniOrangeLogo() {
  return (
    <Box
      component="img"
      src={Logo}
      alt="miniorange-logo"
      sx={{
        height: {
          xl: '40px !important',
          lg: '35px !important',
          md: '20px !important ',
        },
      }}
    />
  );
}
