import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Button, Tabs, Tab,
} from '@mui/material';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import { apps } from '../app';
import { RootState, AppDispatch } from '../../../Redux/store';
import {
  updateEditScreen,
  getAllGroups,
} from '../../../Redux/Slices/Application/appSlice';
import { ResponsiveImage } from '../../../styles/app-logo';
import { ResponsiveHeading } from '../../../styles/heading';
import { lightBackgroundButtonStyles } from '../../../styles/button';
import { buttonIconStyles } from '../../../styles/icon';
import { ResponsiveTypography } from '../../../styles/inputLabel';

interface IProps {
  app: string;
}

function EditScreen({ app }: IProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { editScreen } = useSelector((state: RootState) => state.application);
  useEffect(() => {
    dispatch(getAllGroups());
    dispatch(updateEditScreen('BasicSettings'));
  }, []);
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ padding: { xl: 3, lg: 2 } }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap="1rem"
          pr={3}
          alignItems="center"
        >
          <ResponsiveImage src={apps[app].smallLogo} alt="application-logo" />
          <ResponsiveHeading>{apps[app].title}</ResponsiveHeading>
        </Box>
        <Box>
          <Button
            variant="text"
            sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
            startIcon={(
              <ExtensionOutlinedIcon
                sx={{ ...buttonIconStyles, color: '#373737' }}
              />
            )}
            onClick={() => window.open(
              'https://chromewebstore.google.com/detail/miniorange-casb/bppbenlhlfiiaaeopoaifkgoibonfkbn',
              '_blank',
            )}
          >
            Extension
          </Button>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        sx={{ paddingTop: { xl: 5, md: 2.5 } }}
      >
        <Box
          sx={{ padding: { xl: 3, md: 2 } }}
          border="1px solid #EAEAEA"
          borderRadius="10px"
          p={2}
          width={editScreen === 'BasicSettings' ? '50%' : '100%'}
        >
          <Tabs
            value={editScreen}
            onChange={(event: React.SyntheticEvent, newValue: string) => dispatch(updateEditScreen(newValue))}
          >
            <Tab
              label={
                <ResponsiveTypography>Basic Settings</ResponsiveTypography>
              }
              value="BasicSettings"
            />
            <Tab
              label={
                <ResponsiveTypography>Group Settings</ResponsiveTypography>
              }
              value="GroupSettings"
            />
            <Tab
              label={
                <ResponsiveTypography>Session Management</ResponsiveTypography>
              }
              value="SessionManagement"
            />
          </Tabs>
          <Box>
            {editScreen === 'BasicSettings'
              && apps[app].editScreen?.basicSettings}
            {editScreen === 'GroupSettings'
              && apps[app].editScreen?.groupSettings}
            {editScreen === 'SessionManagement'
              && apps[app].editScreen?.session}
          </Box>
        </Box>
        <Box
          py={2}
          px={10}
          display={editScreen === 'BasicSettings' ? 'flex' : 'none'}
          width="50%"
        >
          {apps[app].configurationScreen?.basicSettingsInfo}
        </Box>
      </Box>
    </Box>
  );
}
export default EditScreen;
