export const lightBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '2px 3px !important',
    lg: '4px 5px !important',
    xl: '5px 7px !important',
    xxl: '5px 7px !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '8px', lg: '7px', md: '5px' },
  backgroundColor: 'white',
  color: '#2D3748',
  border: {
    xl: '1.5px solid #2D3748',
    lg: '1px solid #2D3748',
    md: '1px solid #2D3748',
  },
  '&:hover': {
    backgroundColor: 'white',
    color: '#2D3748',
    border: {
      xl: '1.5px solid #2D3748',
      lg: '1px solid #2D3748',
      md: '1px solid #2D3748',
    },
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
    border: 'none',
  },
};
export const lightBlueBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '2px 3px !important',
    lg: '4px 5px !important',
    xl: '5px 7px !important',
    xxl: '5px 7px !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '8px', lg: '7px', md: '5px' },
  backgroundColor: 'white',
  color: '#0065FF',
  border: {
    xl: '1.5px solid #0065FF',
    lg: '1px solid #0065FF',
    md: '1px solid #0065FF',
  },
  '&:hover': {
    backgroundColor: 'white',
    color: '#0065FF',
    border: {
      xl: '1.5px solid #0065FF',
      lg: '1px solid #0065FF',
      md: '1px solid #0065FF',
    },
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
    border: 'none',
  },
};
export const navyBlueBackgroundButtonStyle = {
  width: 'auto',
  padding: {
    md: '2px 3px !important',
    lg: '3px 5px !important',
    xl: '5px 7px !important',
    xxl: '5px 7px !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '8px', lg: '7px', md: '5px' },
  backgroundColor: '#2D3748',
  color: 'white',
  border: 'none',
  '&:hover': {
    backgroundColor: '#2D3748',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};

export const cyanBlueBackgroundButtonStyles = {
  width: 'auto',
  padding: {
    md: '2px 3px !important',
    lg: '3px 5px !important',
    xl: '5px 7px !important',
    xxl: '5px 7px !important',
  },
  fontSize: {
    md: '0.5rem !important',
    lg: '0.6rem !important',
    xl: '0.8rem !important',
    xxl: '0.9rem !important',
  },
  borderRadius: { xl: '8px', lg: '7px', md: '5px' },
  backgroundColor: '#0052CC',
  color: 'white',
  border: 'none !important',
  '&:hover': {
    backgroundColor: '#0052CC',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};

export const zeroPaddingAutoWidthButton = {
  margin: '0 !important',
  padding: '0 !important',
  width: 'auto !important',
  minWidth: 'auto !important',
};
