import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box, Grid, Fade, Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../components/MainLayout';
import { AppDispatch, RootState } from '../../Redux/store';
import { getSessionDetails } from '../../Redux/Slices/Application/applicationHandler';
import { apps } from '../Application/app';
import { ResponsiveImage } from '../../styles/app-logo';
import { ResponsiveTypography } from '../../styles/inputLabel';
import { ResponsiveHeading } from '../../styles/heading';
import { SmallHeading } from '../../styles/smallHeading';
import { ResponsiveSubHeading } from '../../styles/subHeading';
import { getAllPolicies } from '../../Redux/Slices/Policy/policyHandler';

interface DailyData {
  date: string;
  users: number;
}

function SessionInsight({ appSessionsMap }: any) {
  const [activeSessions, setActiveSessions] = useState(0);
  const [totalSessions, setTotalSessions] = useState(0);

  useEffect(() => {
    if (!appSessionsMap) return;
    let active = 0;
    let total = 0;

    Object.keys(appSessionsMap).forEach((key) => {
      if (Array.isArray(appSessionsMap[key])) {
        appSessionsMap[key].forEach((item: any) => {
          active += item.activeSessions;
          total += item.users.length;
        });
      }
    });

    setActiveSessions(active);
    setTotalSessions(total);
  }, [appSessionsMap]);
  const state = {
    series: [activeSessions, totalSessions - activeSessions],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Active Session', 'Inactive Session'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };
  return (
    <>
      <Chart
        options={state.options}
        series={state.series}
        type="donut"
        width={350}
      />
      <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
        <ResponsiveTypography>
          Total Sesions:
          {totalSessions}
        </ResponsiveTypography>
      </Box>
    </>
  );
}

function Policies({ allPolicies }: any) {
  return (
    <Box my={{ xl: 2 }}>
      <Grid container>
        <Grid xs={3} py={1}>
          <ResponsiveTypography color="#87888C">
            Policy Name
          </ResponsiveTypography>
        </Grid>
        <Grid xs={9} py={1}>
          <ResponsiveTypography color="#87888C">
            Restriction Type
          </ResponsiveTypography>
        </Grid>
        {allPolicies.slice(0, 5).map((policy: any) => {
          let restrictions = 0;
          const restrictionChips = [];
          if (policy.ip_based_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="IP Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.time_based_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="Time Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.file_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="File Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.location_based_restriction.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="Location Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.file_based_restriction.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="File Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.clipboard_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="Clipboard Based"
                color="primary"
                size="small"
              />,
            );
          }
          if (policy.session_restriction_feature.enable) {
            restrictions++;
            restrictionChips.push(
              <Chip
                sx={{ marginX: '1px' }}
                label="Session Based"
                color="primary"
                size="small"
              />,
            );
          }

          return (
            <React.Fragment key={policy._id}>
              <Grid xs={3} py={1}>
                <ResponsiveTypography>{policy.identifier}</ResponsiveTypography>
              </Grid>
              <Grid xs={9} py={1}>
                {restrictionChips.slice(0, 2).map((chip) => (
                  <React.Fragment key={policy._id}>{chip}</React.Fragment>
                ))}
                {restrictions > 2 && (
                  <Chip
                    sx={{ marginX: '1px' }}
                    label={`+${restrictions - 2} more`}
                    color="primary"
                    size="small"
                  />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}

function LineChart({ loginDateMap }: any) {
  const [dailyData, setDailyData] = useState<DailyData[]>([]);

  useEffect(() => {
    if (!loginDateMap) return;

    const dailyDataArray: DailyData[] = Object.entries(loginDateMap).map(
      ([date, users]) => ({
        date,
        users: users as number,
      }),
    );
    setDailyData(dailyDataArray);
  }, [loginDateMap]);

  const series = [
    {
      name: 'Users',
      data: dailyData.map((data) => data.users),
    },
  ];

  const options = {
    chart: {
      type: 'line',
    },
    xaxis: {
      categories: dailyData.map((data) => new Date(data.date).toLocaleDateString()),
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      fill={{
        type: 'solid',
        opacity: 0.5,
      }}
      width="100%"
      height="300"
    />
  );
}

function Dashboard() {
  const navigate = useNavigate();
  const { sessionDetails } = useSelector(
    (state: RootState) => state.application,
  );
  const { allPolicies } = useSelector(
    (state: RootState) => state.policySettings,
  );
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getSessionDetails());
    dispatch(getAllPolicies());
  }, []);
  return (
    <MainLayout>
      <Box display="flex" width="100%">
        <Box display="flex" flexDirection="column" width="70%" p={4}>
          <ResponsiveHeading>Dashboard</ResponsiveHeading>
          <Fade in timeout={1000}>
            <Box display="flex" gap="1rem">
              <Grid container spacing={2} py={3}>
                {Object.values(apps).map((app) => (
                  <Grid item xs={2} key={app.title}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: '100%',
                        borderRadius: '10px',
                        border: '1px solid #D9D9D9',
                        paddingY: 2,
                        paddingX: 3,
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(app.path)}
                      height="10rem"
                      gap="1rem"
                    >
                      <ResponsiveImage src={app.smallLogo} alt={app.title} />
                      <ResponsiveTypography>{app.title}</ResponsiveTypography>
                    </Box>
                  </Grid>
                ))}
                <Grid item xs={2}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: '100%',
                      borderRadius: '10px',
                      border: '1px solid #D9D9D9',
                      paddingY: 2,
                      paddingX: 3,
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/applications')}
                    height="10rem"
                    gap="1rem"
                  >
                    <ResponsiveTypography>View All</ResponsiveTypography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                width: '100%',
                borderRadius: '10px',
                border: '1px solid #D9D9D9',
                paddingY: 2,
                paddingX: 4,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
              }}
            >
              <SmallHeading>Session Overview</SmallHeading>
              <ResponsiveSubHeading pt={{ xl: 1 }}>
                Based on Configured Apps.
              </ResponsiveSubHeading>
              <LineChart loginDateMap={sessionDetails?.loginDateMap} />
            </Box>
          </Fade>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="30%"
          m={4}
          gap={{ xl: '1rem' }}
        >
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              border="1px solid #D9D9D9"
              p={2}
            >
              <SmallHeading>Session Insight</SmallHeading>
              <SessionInsight appSessionsMap={sessionDetails?.appSessionsMap} />
            </Box>
          </Fade>
          <Fade in timeout={1000}>
            <Box
              display="flex"
              flexDirection="column"
              borderRadius="10px"
              border="1px solid #D9D9D9"
              p={2}
            >
              <SmallHeading>Policies</SmallHeading>
              <Policies allPolicies={allPolicies} />
            </Box>
          </Fade>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Dashboard;
