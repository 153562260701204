import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }: any) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    height: '1.5rem !important',
    '& .MuiInputBase-input': {
      fontSize: '8px !important',
      padding: '3px 4px !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    height: '1.85rem !important',
    '& .MuiInputBase-input': {
      fontSize: '10px !important',
      padding: '5px 7px !important',
    },
  },
  [theme.breakpoints.up('xl')]: {
    height: '2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '13px !important',
      padding: '5.5px 14px !important',
    },
  },
  [theme.breakpoints.up('xxl')]: {
    height: '2.2rem !important',
    '& .MuiInputBase-input': {
      fontSize: '15px !important',
      padding: '5.5px 14px !important',
    },
  },
}));
