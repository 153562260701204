import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { updateId } from '../../../Redux/Slices/Policy/Proxy/proxy';
import { RootState } from '../../../Redux/store';
import { getCookie, isValidPath, isValidURL } from '../../../utils/utility';
import {
  cyanBlueBackgroundButtonStyles,
  lightBackgroundButtonStyles,
} from '../../../styles/button';
import Restriction from './EnableFeaturesView/Restriction';
import CachingPerformance from './EnableFeaturesView/Caching&Performance';
import LoadBalancingRateLimiting from './EnableFeaturesView/LoadBalancingRateLimiting';
import CookieDomainRewrite from './EnableFeaturesView/CookieDomainRewrite';
import Authentication from './EnableFeaturesView/Authentication';
import Substitution from './EnableFeaturesView/Substitution';
import CORS from './EnableFeaturesView/CORS';
import { ResponsiveTypography } from '../../../styles/inputLabel';

interface EnableFeaturesProps {
  step: number;
  setStep: Function;
  type: string;
}
function EnableFeatures({
  step,
  setStep,
  type,
}: EnableFeaturesProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const proxySettings = useSelector((state: RootState) => state.proxySettings);
  const [enableFeatureStep, setEnableFeatureStep] = useState('Restriction ');
  const featureComponents: { [key: string]: React.ComponentType } = {
    'Restriction ': Restriction,
    'Caching & Performance': CachingPerformance,
    'Rate Limiting/Load Balancing': LoadBalancingRateLimiting,
    'Cookie Domain Rewrite': CookieDomainRewrite,
    'Authentication ': Authentication,
    'CORS ': CORS,
    'Substitution ': Substitution,
  };
  const handleEnableFeatures = () => {
    if (
      !proxySettings
      || !proxySettings.proxyName
      || !proxySettings.proxyLink
      || !proxySettings.primaryServerURL
    ) {
      toast.error('Missing Required Fields');
    } else if (!isValidPath(proxySettings.proxyLink)) {
      toast.error('Invalid Proxy Path please enter a valid Path.');
    } else if (!isValidURL(proxySettings.primaryServerURL)) {
      toast.error('Invalid Server URL please enter a valid URL.');
    } else {
      const token = getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-new-proxy`,
          {
            proxySettings,
            type,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          dispatch(updateId(res.data.id));
          navigate('/configure/proxy');
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" height="30rem" pt={2}>
        <Box width="30%" pl={2} pr={2} position="relative">
          <Box
            sx={{
              backgroundImage: 'linear-gradient(#FFFFFF, #0065FF, #FFFFFF)',
              width: '4px',
              borderRadius: '2px',
              height: {
                xs: '7rem',
                sm: '10rem',
                md: '17rem',
                lg: '20rem',
                xl: '20rem',
              },
              position: 'absolute',
              top: 0,
              bottom: 0,
            }}
          />
          <Box sx={{ width: 'calc(100% - 1rem)', gap: '1.5rem' }}>
            {Object.keys(featureComponents).map((feature) => (
              <Box
                key={feature}
                display="flex"
                alignItems="center"
                sx={{
                  paddingY: { xl: 1, lg: 1.5 },
                  cursor: 'pointer',
                  backgroundColor: enableFeatureStep === feature ? '#E0F2FF' : 'white',
                  paddingLeft: '1rem',
                  borderRadius: '7px',
                  transition: 'background-color 0.3s',
                  borderLeft: enableFeatureStep === feature ? '6px solid #0065FF' : '',
                }}
                onClick={() => setEnableFeatureStep(feature)}
              >
                <ResponsiveTypography variant="subtitle1" color="#505050">
                  {feature}
                </ResponsiveTypography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box width="70%">
          <Box
            id="feature-configuration"
            sx={{
              height: '43vh',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              p: 2,
            }}
          >
            {React.createElement(featureComponents[enableFeatureStep])}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="right"
        pt={2}
        sx={{ gap: '1rem' }}
      >
        <Button
          variant="text"
          sx={{ ...lightBackgroundButtonStyles, width: '7rem' }}
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          variant="text"
          sx={{ ...cyanBlueBackgroundButtonStyles, width: '7rem' }}
          onClick={handleEnableFeatures}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default EnableFeatures;
