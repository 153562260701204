export const smallIconStyles = {
  fontSize: {
    xl: '20px !important',
    lg: '20px !important',
    md: '18px !important',
  },
  color: '#171821',
  cursor: 'pointer !important',
};

export const mediumIconStyles = {
  fontSize: { xl: '25px !important', lg: '20px !important' },
  padding: 0,
  margin: 0,
  color: '#171821',
  cursor: 'pointer !important',
};
export const buttonIconStyles = {
  fontSize: {
    xl: '18px !important',
    lg: '15px !important',
    md: '12px !important',
  },
  padding: 0,
  margin: 0,
  color: '#171821',
  cursor: 'pointer !important',
};
