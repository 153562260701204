import React from 'react';
import officelogo from '../../assets/images/office365-logo.png';
import officelogoShort from '../../assets/images/office3651.png';
import googlelogo from '../../assets/images/Google_Full.png';
import googlelogoShort from '../../assets/images/google-logo.png';
import atlassianlogo from '../../assets/images/atlassian.png';
import atlassianlogoShort from '../../assets/images/atlassian-logo.png';
import shopifylogo from '../../assets/images/shopify_nonplus.png';
import shopifylogoShort from '../../assets/images/shopify.png';
import shopifypluslogo from '../../assets/images/shopify-plus-logo.png';
import shopifypluslogoShort from '../../assets/images/shopify-plus.png';
import Atlassian from '../IntegratedApps/Atlassian/atlassian';
import Office365 from '../IntegratedApps/Office365/office365';
import Google from '../IntegratedApps/Google/google';
import Shopify from '../IntegratedApps/Shopify/shopify';
import ShopifyPlus from '../IntegratedApps/Shopify-Plus/shopifyPlus';
import BasicSettings from './Configuration/BasicSettings/Common/basicSettings';
import BasicSettingsInformation from './Configuration/BasicSettings/Common/info&links';
import ShopifyBasicSettings from './Configuration/BasicSettings/Custom/Shopify/basicSettings';
import ShopifyBasicSettingsInformation from './Configuration/BasicSettings/Custom/Shopify/info&links';
import ShopifyPlusBasicSettings from './Configuration/BasicSettings/Custom/ShopifyPlus/basicSettings';
import CommonBasicSettings from './Edit/BasicSettings/common/basicSettings';
import EditShopifyBasicSettings from './Edit/BasicSettings/Custom/Shopify/basicSettings';
import EditShopifyPlusBasicSettings from './Edit/BasicSettings/Custom/ShopifyPlus/basicSettings';
import GroupSettings from './Configuration/AppConfiguration/ManageGroups/Common/groupSettings';
import AtlassianGroupSettings from './Configuration/AppConfiguration/ManageGroups/Atlassian/groupSettings';
import Office365GroupSettings from './Configuration/AppConfiguration/ManageGroups/Office365/groupSettings';
import EditGroupSettings from './Edit/AppConfiguration/GroupSettings/Common/commonGroupSettings';
import GroupSettingsTable from './Edit/AppConfiguration/GroupSettings/groupSettings';
import EditAtlassianGroupSettings from './Edit/AppConfiguration/GroupSettings/Custom/atlassianGroupConfiguration';
import EditOffice365GroupSettings from './Edit/AppConfiguration/GroupSettings/Custom/office365GroupConfiguration';
import ShopifyNonPlusUserConfiguration from './Configuration/AppConfiguration/UserConfiguration/ShopifyNonPlus/userConfiguration';
import ShopifyPlusGroupSettings from './Configuration/AppConfiguration/ManageGroups/ShopifyPlus/groupSettings';
import SessionManagement from './Edit/SessionManagement/sessionManagement';
import OfficeInfo from './Custom/office';

export const apps = {
  [process.env.REACT_APP_OFFICE365_KEY!]: {
    path: '/casb/office365',
    logo: officelogo,
    smallLogo: officelogoShort,
    appConfigurationTitle: 'Group Settings',
    title: 'Office365',
    mainComponent: <Office365 />,
    configurationScreen: {
      basicSettingScreen: <BasicSettings />,
      basicSettingsInfo: <BasicSettingsInformation />,
      groupSettingScreen: <Office365GroupSettings />,
    },
    editScreen: {
      basicSettings: <CommonBasicSettings />,
      groupSettings: <GroupSettingsTable />,
      groupAppSettings: <EditOffice365GroupSettings />,
      session: <SessionManagement />,
    },
    appConfiguration: <OfficeInfo />,
    guide:
      'https://www.miniorange.in/casb/integrations/how-to-setup-microsoft-365-with-casb-solution',
  },
  [process.env.REACT_APP_GOOGLE_KEY!]: {
    path: '/casb/google',
    logo: googlelogo,
    smallLogo: googlelogoShort,
    appConfigurationTitle: 'Group Settings',
    title: 'Google',
    mainComponent: <Google />,
    configurationScreen: {
      basicSettingScreen: <BasicSettings />,
      basicSettingsInfo: <BasicSettingsInformation />,
      groupSettingScreen: <GroupSettings />,
    },
    editScreen: {
      basicSettings: <CommonBasicSettings />,
      groupSettings: <GroupSettingsTable />,
      groupAppSettings: <EditGroupSettings />,
      session: <SessionManagement />,
    },
    guide:
      'https://www.miniorange.com/casb/integrations/how-to-setup-casb-for-google-workspace-apps',
  },
  [process.env.REACT_APP_ATLASSIAN_KEY!]: {
    path: '/casb/atlassian',
    logo: atlassianlogo,
    smallLogo: atlassianlogoShort,
    appConfigurationTitle: 'Group Settings',
    title: 'Atlassian',
    mainComponent: <Atlassian />,
    configurationScreen: {
      basicSettingScreen: <BasicSettings />,
      basicSettingsInfo: <BasicSettingsInformation />,
      groupSettingScreen: <AtlassianGroupSettings />,
    },
    editScreen: {
      basicSettings: <CommonBasicSettings />,
      groupSettings: <GroupSettingsTable />,
      groupAppSettings: <EditAtlassianGroupSettings />,
      session: <SessionManagement />,
    },
    guide:
      'https://www.miniorange.com/casb/integrations/how-to-setup-casb-for-atlassian',
  },
  [process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY!]: {
    path: '/casb/shopify',
    logo: shopifylogo,
    smallLogo: shopifylogoShort,
    appConfigurationTitle: 'Groups & User Configuration',
    title: 'Shopify',
    mainComponent: <Shopify />,
    configurationScreen: {
      basicSettingScreen: <ShopifyBasicSettings />,
      basicSettingsInfo: <ShopifyBasicSettingsInformation />,
      groupSettingScreen: <ShopifyNonPlusUserConfiguration />,
    },
    editScreen: {
      basicSettings: <EditShopifyBasicSettings />,
      groupSettings: <ShopifyNonPlusUserConfiguration />,
      session: <SessionManagement />,
    },
    guide:
      'https://www.miniorange.com/casb/integrations/how-to-setup-casb-for-shopify-non-plus',
  },
  [process.env.REACT_APP_SHOPIFY_PLUS_KEY!]: {
    path: '/casb/shopify-plus',
    logo: shopifypluslogo,
    smallLogo: shopifypluslogoShort,
    appConfigurationTitle: 'Group Settings',
    title: 'Shopify +',
    mainComponent: <ShopifyPlus />,
    configurationScreen: {
      basicSettingScreen: <ShopifyPlusBasicSettings />,
      basicSettingsInfo: <BasicSettingsInformation />,
      groupSettingScreen: <ShopifyPlusGroupSettings />,
    },
    editScreen: {
      basicSettings: <EditShopifyPlusBasicSettings />,
      groupSettings: <GroupSettingsTable />,
      groupAppSettings: <ShopifyPlusGroupSettings />,
      session: <SessionManagement />,
    },
    guide:
      'https://www.miniorange.com/casb/integrations/how-to-setup-casb-for-shopify-plus',
  },
};
